var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useRecoilState } from 'recoil';
import { useRequest } from '../../hooks/useRequest';
import { recordingsAtom, recordingsLoadingAtom, recordingsFileLoadingAtom } from '../store';
export var useAutoTrainingRecordings = function () {
    var request = useRequest();
    var _a = useRecoilState(recordingsAtom), recordings = _a[0], setRecordings = _a[1];
    var _b = useRecoilState(recordingsLoadingAtom), recordingsLoading = _b[0], setRecordingsLoading = _b[1];
    var _c = useRecoilState(recordingsFileLoadingAtom), uploadRecordingLoading = _c[0], setUploadRecordingsLoading = _c[1];
    var getRecordings = function () {
        setRecordingsLoading(true);
        request.get('/CallRecordings')
            .then(function (_a) {
            var data = _a.data;
            setRecordings(data);
        })
            .catch(console.log)
            .finally(function () { return setRecordingsLoading(false); });
    };
    var startUploadProcessing = function () {
        void request.post('/File/startprocessing');
    };
    var uploadRecordings = function (file) {
        setUploadRecordingsLoading(true);
        request.post('/CallRecordings', file, { headers: { "Content-Type": "multipart/form-data" } })
            .then(function (_a) {
            var data = _a.data;
            setRecordings(__spreadArray(__spreadArray([], recordings, true), [__assign(__assign({}, data), { isProcessing: true })], false));
            startUploadProcessing();
        })
            .catch(function (error) {
            console.log(error);
        })
            .finally(function () { return setUploadRecordingsLoading(false); });
    };
    var deleteRecording = function (fileId) {
        request.delete("/CallRecordings/".concat(fileId))
            .then(function () { return setRecordings(recordings.children.filter(function (item) { return item.fileId !== fileId; })); })
            .catch(console.log);
    };
    return { getRecordings: getRecordings, recordings: recordings, recordingsLoading: recordingsLoading, uploadRecordings: uploadRecordings, uploadRecordingLoading: uploadRecordingLoading, deleteRecording: deleteRecording };
};
