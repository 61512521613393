import { useRecoilState } from 'recoil';
import { managedWorkspacesAtom } from '../store';
import { useRequest } from '../../hooks/useRequest';
export var useWorkspace = function () {
    var _a = useRecoilState(managedWorkspacesAtom), managedWorkspaces = _a[0], setManagedWorkspaces = _a[1];
    var request = useRequest();
    var getManagedWorkspaces = function () {
        request
            .get('/workspace/managedworkspaces')
            .then(function (_a) {
            var data = _a.data;
            return setManagedWorkspaces(data);
        })
            .catch(console.log);
    };
    return {
        managedWorkspaces: managedWorkspaces,
        getManagedWorkspaces: getManagedWorkspaces,
    };
};
