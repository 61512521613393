var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import React from 'react';
import { useLayer } from 'react-laag';
// components
import Icon from '../Icon';
import View from '../../styledComponents/View';
import NowrapText from '../../styledComponents/NowrapText';
import ItemStyled from './styled/ItemStyled';
import FilterStyled from './styled/FilterStyled';
// constants
import { HIGH_Z_INDEXES } from '../../../constants';
// hooks
import useIsScrolling from '../../../hooks/useIsScrolling';
var Menu = function (props) {
    var data = props.data, name = props.name, value = props.value, width = props.width, filter = props.filter, children = props.children, placement = props.placement, _a = props.isShow, isShow = _a === void 0 ? null : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.valueProp, valueProp = _c === void 0 ? 'name' : _c, _d = props.labelProp, labelProp = _d === void 0 ? 'name' : _d, onSelect = props.onSelect, renderItem = props.renderItem, renderContent = props.renderContent, renderFooter = props.renderFooter, renderHeader = props.renderHeader;
    var _e = React.useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var _f = React.useState(data), list = _f[0], setList = _f[1];
    var _g = React.useState(''), filterText = _g[0], setFilterText = _g[1];
    var filterRef = React.useRef(null);
    var _h = useIsScrolling(), isScrolling = _h.isScrolling, handleRef = _h.handleRef;
    React.useEffect(function () {
        var _a;
        if (isOpen) {
            (_a = filterRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            setFilterText('');
        }
    }, [isOpen]);
    React.useEffect(function () {
        if (filterText) {
            setList(data === null || data === void 0 ? void 0 : data.filter(function (item) {
                return "".concat(item[labelProp]).toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
            }));
        }
        else {
            setList(data);
        }
    }, [filterText, data]);
    var close = function () {
        setIsOpen(false);
    };
    var _j = useLayer({
        isOpen: (isOpen && isShow === null) || isShow,
        onOutsideClick: close,
        placement: placement,
        overflowContainer: true,
        triggerOffset: 8,
        auto: true
    }), triggerProps = _j.triggerProps, layerProps = _j.layerProps, renderLayer = _j.renderLayer;
    var _select = function (data) {
        close();
        if (typeof onSelect === 'function') {
            onSelect(data, name);
        }
    };
    var _content = function () {
        if (typeof renderContent === 'function')
            return renderContent({ close: close });
        if (typeof renderItem === 'function') {
            return (_jsxs(_Fragment, { children: [renderHeader && renderHeader({ close: close }), _jsx(View, __assign({ padding: typeof renderContent === 'function'
                            ? 0
                            : { vertical: 6, horizontal: 4 } }, { children: list === null || list === void 0 ? void 0 : list.map(function (item, index) { return (_jsx(ItemStyled, __assign({ index: index, active: item[valueProp] === value, onClick: function () { return _select(item[valueProp]); } }, { children: renderItem(item, value) }), item[valueProp])); }) })), renderFooter && renderFooter({ close: close })] }));
        }
        return (_jsxs(_Fragment, { children: [renderHeader && renderHeader(), _jsx(View, __assign({ padding: typeof renderContent === 'function' ? 0 : { vertical: 6, horizontal: 4 } }, { children: list === null || list === void 0 ? void 0 : list.map(function (item, index) { return (_jsx(ItemStyled, __assign({ index: index, active: item[valueProp] === value, padding: { horizontal: 8 }, onClick: function () { return _select(item[valueProp]); } }, { children: _jsx(NowrapText, __assign({ size: 14 }, { children: item[labelProp] })) }), item[valueProp])); }) })), renderFooter && renderFooter()] }));
    };
    return (_jsxs(View, { children: [_jsx(View, __assign({}, triggerProps, { onClick: function () { return !disabled && setIsOpen(!isOpen); } }, { children: children })), ((isOpen && isShow === null) || isShow) &&
                renderLayer(_jsxs(View, __assign({ radius: 4, width: width, shadow: "dreamy", bgColor: "white" }, layerProps, { zIndex: HIGH_Z_INDEXES.LEVEL_2 }, { children: [filter && !!(data === null || data === void 0 ? void 0 : data.length) && (data === null || data === void 0 ? void 0 : data.length) > 6 && !renderContent && (_jsxs(View, __assign({ flex: true, aCenter: true, borderB: "1px solid", borderColor: "theme", padding: { horizontal: 16 } }, { children: [_jsx(Icon, { name: "searchList", color: "dark50", size: 14 }), _jsx(FilterStyled, { ref: filterRef, value: filterText, placeholder: "Search", onChange: function () { var _a; return setFilterText(((_a = filterRef.current) === null || _a === void 0 ? void 0 : _a.value) || ''); } })] }))), _jsx(View, __assign({ padding: { right: isScrolling ? 4 : 0 } }, { children: _jsx(View, __assign({ maxH: 300, overflow: "auto", onClick: close, ref: handleRef, scrollbar: "small", onScroll: function (e) { return e.stopPropagation(); } }, { children: _content() })) }))] })))] }));
};
Menu.defaultProps = {
    data: [],
    width: 200,
    filter: true,
};
export default Menu;
