var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./components/ui/Sidebar";
import Header from "../src/components/ui/Header";
import TagManager from "react-gtm-module";
import Main from "./Main";
import { ImpersonationProvider } from "./contexts/ImpersonationContext";
import { ImpersonationBanner } from "./components/common/ImpersonationBanner";
function App() {
    useEffect(function () {
        // WebFont.load({
        //   google: {
        //     families: ['Poppins:275,500,700', 'Source Code Pro:400']
        //   }
        // });
        var tagManagerArgs = {
            gtmId: "GTM-TMJ7BX4",
        };
        TagManager.initialize(tagManagerArgs);
    }, []);
    return (_jsxs(ImpersonationProvider, { children: [_jsx(ImpersonationBanner, {}), _jsxs(CssVarsProvider, __assign({ disableTransitionOnChange: true }, { children: [_jsx(CssBaseline, {}), _jsxs(Box, __assign({ sx: { display: "flex", minHeight: "100dvh" } }, { children: [_jsx(Header, {}), _jsx(Sidebar, {}), _jsx(Main, {})] }))] }))] }));
}
export default App;
