import { useRecoilState } from 'recoil';
import { useRequest } from '../../hooks/useRequest';
import { hubSpotIdAtom, hubSpotIdLoadingAtom, connectHubSpotUrlAtom, } from '../store';
export var useHubSpot = function () {
    var request = useRequest();
    var _a = useRecoilState(connectHubSpotUrlAtom), connectHubSpotUrl = _a[0], setConnectHubSpotUrl = _a[1];
    var _b = useRecoilState(hubSpotIdAtom), hubSpotId = _b[0], setHubSpotId = _b[1];
    var _c = useRecoilState(hubSpotIdLoadingAtom), hubSpotIdLoading = _c[0], setHubSpotIdLoading = _c[1];
    var getHubSpotConnectUrl = function () {
        request.get('/Hubspot/authorize/url')
            .then(function (_a) {
            var data = _a.data;
            return setConnectHubSpotUrl(data);
        })
            .catch(console.log);
    };
    var connectHubSpot = function () {
        if (connectHubSpotUrl) {
            // @ts-ignore
            window.location = connectHubSpotUrl;
        }
    };
    var getHubSpotIntegration = function () {
        setHubSpotIdLoading(true);
        request.get('/Hubspot/integration')
            .then(function (_a) {
            var data = _a.data;
            return setHubSpotId(data);
        })
            .catch(console.log)
            .finally(function () { return setHubSpotIdLoading(false); });
    };
    return {
        hubSpotId: hubSpotId,
        hubSpotIdLoading: hubSpotIdLoading,
        connectHubSpot: connectHubSpot,
        getHubSpotConnectUrl: getHubSpotConnectUrl,
        getHubSpotIntegration: getHubSpotIntegration,
    };
};
