var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import View from '../../components/styledComponents/View';
import ReactLoading from 'react-loading';
import { colors } from '../../styles/theme';
function SignIn() {
    var _a = useAuth0(), loginWithRedirect = _a.loginWithRedirect, isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading;
    var navigate = useNavigate();
    useEffect(function () {
        if (!isLoading) {
            if (isAuthenticated) {
                navigate('/');
            }
            else {
                loginWithRedirect();
            }
        }
    }, [isLoading, isAuthenticated, navigate, loginWithRedirect]);
    if (!isLoading && isAuthenticated) {
        navigate('/');
    }
    return (_jsx(View, __assign({ height: "100vh", flex: true, aCenter: true, jCenter: true }, { children: _jsx(ReactLoading, { type: "spin", color: colors.theme, height: 30, width: 30 }) })));
}
export default SignIn;
