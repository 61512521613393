var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// components/ManageWorkspaces.tsx
import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { Box, Dropdown, IconButton, Menu, MenuButton, MenuItem, Stack, Typography, } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import { useWorkspace } from "../../data/hooks/useWorkspace";
import ImpersonateIcon from "@mui/icons-material/Person";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./styles.css";
import MoreVert from "@mui/icons-material/MoreVert";
import { useImpersonation } from "../../contexts/ImpersonationContext";
var ActionDropdown = function (_a) {
    var workspaceId = _a.workspaceId, workspaceName = _a.workspaceName, onImpersonate = _a.onImpersonate;
    var handleImpersonateClick = function () {
        onImpersonate(workspaceId, workspaceName);
    };
    return (_jsxs(Dropdown, { children: [_jsx(MenuButton, __assign({ slots: { root: IconButton }, slotProps: {
                    root: { variant: "plain", color: "neutral", size: "small" },
                } }, { children: _jsx(MoreVert, {}) })), _jsx(Menu, __assign({ placement: "bottom-end" }, { children: _jsxs(MenuItem, __assign({ onClick: handleImpersonateClick }, { children: [_jsx(ImpersonateIcon, { sx: { marginRight: 1 } }), "Impersonate"] })) }))] }));
};
export var ManageWorkspaces = function () {
    var _a = useWorkspace(), managedWorkspaces = _a.managedWorkspaces, getManagedWorkspaces = _a.getManagedWorkspaces;
    var _b = useState(true), darkMode = _b[0], setDarkMode = _b[1];
    var mode = useColorScheme().mode;
    var startWorkspaceImpersonation = useImpersonation().startWorkspaceImpersonation;
    useEffect(function () {
        getManagedWorkspaces();
    }, []);
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    var handleImpersonateClick = function (workspaceId, workspaceName) {
        startWorkspaceImpersonation(workspaceId, workspaceName);
    };
    var rowData = managedWorkspaces.map(function (workspace) { return ({
        workspaceName: workspace.workspaceName,
        externalWorkspaceId: workspace.externalWorkspaceId || "N/A",
        monthlyMinutesUsed: workspace.monthlyMinutesUsed,
        workspaceId: workspace.workspaceId,
    }); });
    var defaultColDef = useMemo(function () {
        return {
            filter: true,
            resizable: true,
        };
    }, []);
    var columnDefs = [
        {
            headerName: "Workspace Name",
            field: "workspaceName",
            flex: 2,
            cellRenderer: function (params) { return (_jsx(Typography, __assign({ level: "body-sm", sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                } }, { children: params.value }))); },
        },
        {
            headerName: "External Workspace ID",
            field: "externalWorkspaceId",
            flex: 2,
            cellRenderer: function (params) { return (_jsx(Typography, __assign({ level: "body-sm", sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                } }, { children: params.value }))); },
        },
        {
            headerName: "Monthly Minutes Used",
            field: "monthlyMinutesUsed",
            flex: 1,
            cellRenderer: function (params) { return (_jsx(Typography, __assign({ level: "body-sm", sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                } }, { children: params.value }))); },
        },
        {
            headerName: "Actions",
            field: "actions",
            sortable: false,
            filter: false,
            width: 100,
            maxWidth: 100,
            cellRenderer: function (params) { return (_jsx(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                } }, { children: _jsx(ActionDropdown, { workspaceId: params.data.workspaceId, workspaceName: params.data.workspaceName, onImpersonate: handleImpersonateClick }) }))); },
        },
    ];
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: _jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: "Manage Workspaces" })) })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: _jsx(Box, __assign({ sx: { width: "100%", height: "100%", paddingBottom: 1 }, className: !darkMode ? "ag-theme-quartz" : "ag-theme-quartz-dark" }, { children: _jsx(AgGridReact, { rowData: rowData, columnDefs: columnDefs, pagination: true, defaultColDef: defaultColDef }) })) }))] })));
};
