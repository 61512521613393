var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box, IconButton, Stack, Skeleton, Menu, MenuButton, MenuItem, Dropdown, Chip, Typography, Link, Button, Grid, } from "@mui/joy";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { experimental_extendTheme as materialExtendTheme, Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID, } from "@mui/material/styles";
import MoreVert from "@mui/icons-material/MoreVert";
import MDEditor from "@uiw/react-md-editor";
import { useAutoGeneratedContent } from "../../../data/hooks/useAutoGeneratedContent";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "../../../components/ui/MdEditorStyles/editorStyles.css";
import { LOADING_STATE } from "../../../data/constants/useAutoGeneratedContentloadingStates";
var materialTheme = materialExtendTheme();
export var AutoGeneratedInstructions = function () {
    var _a;
    var _b = useAutoGeneratedContent(), getGeneratedInstructions = _b.getGeneratedInstructions, generatedInstructions = _b.generatedInstructions, deleteGeneratedInstruction = _b.deleteGeneratedInstruction, changeInstructionDraftState = _b.changeInstructionDraftState, getInstructionContent = _b.getInstructionContent, updateInstructionContent = _b.updateInstructionContent, instruction = _b.instruction, loadingStates = _b.loadingStates;
    var _c = useState(null), editingFileId = _c[0], setEditingFileId = _c[1];
    var _d = useState(""), editingContent = _d[0], setEditingContent = _d[1];
    var _e = useState(""), editingFileName = _e[0], setEditingFileNameContent = _e[1];
    useEffect(function () {
        getGeneratedInstructions();
    }, []);
    useEffect(function () {
        if (editingFileId) {
            getInstructionContent(editingFileId);
        }
    }, [editingFileId]);
    useEffect(function () {
        setEditingContent((instruction === null || instruction === void 0 ? void 0 : instruction.fileContent) || "");
        setEditingFileNameContent((instruction === null || instruction === void 0 ? void 0 : instruction.fileName) || "");
    }, [instruction]);
    var handleEdit = function (fileId) {
        setEditingFileId(fileId);
    };
    var handleSave = function () {
        if (editingFileId) {
            updateInstructionContent(editingFileId, editingContent);
        }
    };
    var handleContentChange = function (value) {
        if (value !== undefined) {
            setEditingContent(value);
        }
    };
    var handleBack = function () {
        setEditingFileId(null);
        setEditingContent("");
        setEditingFileNameContent("");
    };
    var columns = [
        {
            field: "fileName",
            headerName: "Name",
            flex: 4,
            renderCell: function (params) { return (_jsx(Link, __assign({ underline: "none" }, { children: _jsx(Typography, __assign({ level: "body-sm", color: "primary", onClick: function () { return handleEdit(params.row.fileId); } }, { children: params.row.fileDisplayName })) }))); },
        },
        {
            field: "isDraft",
            headerName: "State",
            flex: 1,
            renderCell: function (params) {
                return params.row.isDraft ? (_jsx(Chip, __assign({ color: "neutral" }, { children: _jsx(Typography, __assign({ level: "body-sm", color: "neutral" }, { children: "Draft" })) }))) : (_jsx(Chip, __assign({ color: "success" }, { children: _jsx(Typography, __assign({ level: "body-sm", color: "neutral" }, { children: "Published" })) })));
            },
        },
        {
            field: "sessionUrl",
            headerName: "Source session",
            flex: 1,
            renderCell: function (params) { return (_jsx(Link, __assign({ underline: "none", target: "_blank", href: params.row.sessionUrl }, { children: _jsx(Typography, __assign({ level: "body-sm", color: "primary" }, { children: "View Source" })) }))); },
        },
        {
            field: "actions",
            headerName: "",
            type: "actions",
            width: 50,
            renderCell: function (params) { return (_jsx(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }, { children: _jsxs(Dropdown, { children: [_jsx(MenuButton, __assign({ slots: { root: IconButton }, slotProps: { root: { variant: "plain", color: "neutral" } } }, { children: _jsx(MoreVert, {}) })), _jsxs(Menu, __assign({ placement: "bottom-end", size: "sm" }, { children: [params.row.isDraft ? (_jsx(MenuItem, __assign({ onClick: function (e) {
                                        e.stopPropagation();
                                        changeInstructionDraftState(params.row.fileId, false); // Mark as published
                                    }, style: { color: "green" } }, { children: loadingStates[LOADING_STATE.CHANGE_INSTRUCTION_DRAFT_STATE(params.row.fileId)] ? (_jsx(Skeleton, { width: 100 })) : ("Publish") }))) : (_jsx(MenuItem, __assign({ onClick: function (e) {
                                        e.stopPropagation();
                                        changeInstructionDraftState(params.row.fileId, true); // Mark as draft
                                    }, style: { color: "orange" } }, { children: loadingStates[LOADING_STATE.CHANGE_INSTRUCTION_DRAFT_STATE(params.row.fileId)] ? (_jsx(Skeleton, { width: 100 })) : ("Mark as Draft") }))), _jsx(MenuItem, __assign({ variant: "soft", color: "danger", onClick: function (e) {
                                        e.stopPropagation();
                                        deleteGeneratedInstruction(params.row.fileId);
                                    } }, { children: loadingStates[LOADING_STATE.DELETE_GENERATED_INSTRUCTION(params.row.fileId)] ? (_jsx(Skeleton, { width: 100 })) : ("Delete") }))] }))] }) }))); },
        },
    ];
    if (editingFileId) {
        return (_jsx(Grid, __assign({ container: true, spacing: 1, padding: 2, sx: { height: "100vh" } }, { children: _jsxs(Stack, __assign({ direction: "column", spacing: 1, sx: { width: "100%", height: "100%" } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, __assign({ level: "h2", component: "h1" }, { children: editingFileName })), _jsx(IconButton, __assign({ size: "sm", onClick: handleBack }, { children: _jsx(CloseRoundedIcon, {}) }))] })), loadingStates[LOADING_STATE.GET_ARTICLE_CONTENT(editingFileId)] ? (_jsxs(Grid, __assign({ container: true, spacing: 2, sx: { padding: 2, width: "100%" } }, { children: [_jsx(Grid, __assign({ xs: 6, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 250 }) })), _jsx(Grid, __assign({ xs: 6, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 250 }) }))] }))) : (_jsx(MDEditor, { value: editingContent, onChange: handleContentChange, className: "editor-container", height: "100vh" })), _jsxs(Stack, __assign({ spacing: 1, direction: "row", justifyContent: "flex-end", mr: 3 }, { children: [_jsx(Button, __assign({ loading: loadingStates[LOADING_STATE.UPDATE_INSTRUCTION_CONTENT(editingFileId)], onClick: handleSave }, { children: "Save" })), _jsx(Button, __assign({ color: "neutral", onClick: handleBack }, { children: "Cancel" }))] }))] })) })));
    }
    return (_jsx(Grid, __assign({ container: true, spacing: 1, padding: 2, sx: { height: "100vh" } }, { children: _jsxs(Stack, __assign({ direction: "column", spacing: 1, sx: { width: "100%", height: "100%" } }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: _jsx(Typography, __assign({ level: "h2", component: "h1" }, { children: "Generated Instructions" })) })), _jsx(MaterialCssVarsProvider, __assign({ theme: (_a = {}, _a[MATERIAL_THEME_ID] = materialTheme, _a) }, { children: _jsx(DataGrid, { rows: generatedInstructions || [], columns: columns, autoPageSize: true, pagination: true, getRowId: function (row) { return row.fileId; }, slots: { toolbar: GridToolbar }, slotProps: { toolbar: { csvOptions: { delimiter: ";" } } }, initialState: {
                            density: "compact",
                        } }) }))] })) })));
};
