// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-quartz-dark {
    /* Changes the color of the grid text */
    --ag-foreground-color: #EDF5FD;
    /* Changes the color of the grid background */
    --ag-background-color: #0b0d0e;
    /* Changes the header color of the top row */
    --ag-header-background-color: #0b0d0e;
    /* Changes the hover color of the row*/
    --ag-row-hover-color: #121314;
}`, "",{"version":3,"sources":["webpack://./src/pages/DeepCallLogs/styles.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,6CAA6C;IAC7C,8BAA8B;IAC9B,4CAA4C;IAC5C,qCAAqC;IACrC,sCAAsC;IACtC,6BAA6B;AACjC","sourcesContent":[".ag-theme-quartz-dark {\n    /* Changes the color of the grid text */\n    --ag-foreground-color: #EDF5FD;\n    /* Changes the color of the grid background */\n    --ag-background-color: #0b0d0e;\n    /* Changes the header color of the top row */\n    --ag-header-background-color: #0b0d0e;\n    /* Changes the hover color of the row*/\n    --ag-row-hover-color: #121314;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
