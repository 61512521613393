var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import icons from './icons';
import SvgStyled from './styled/SvgStyled';
var Icon = function (props) {
    var name = props.name, color = props.color, size = props.size, width = props.width, height = props.height;
    return (_jsx(SvgStyled, __assign({ color: color, width: width || size, height: height || size }, { children: _jsx("use", { xlinkHref: "#".concat(icons[name].id) }) })));
};
Icon.defaultProps = {
    size: 16,
    color: 'black'
};
export default Icon;
