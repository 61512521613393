var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton, Box } from "@mui/joy";
var ChatListSkeleton = function () {
    return (_jsx(Box, __assign({ sx: { display: "flex", flexDirection: "row", height: "100vh" } }, { children: _jsx(Box, __assign({ sx: {
                width: "100%",
                p: 2,
                borderRight: "1px solid #ccc", // Divider between left and right panels
            } }, { children: __spreadArray([], Array(10), true).map(function (_, index) { return (_jsx(Box, __assign({ sx: { mb: 2 } }, { children: _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 80 }) }), index)); }) })) })));
};
export default ChatListSkeleton;
