var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '../../../../styles/theme';
var SvgStyled = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var color = _a.color, width = _a.width, height = _a.height;
    return "\n      width: ".concat(width, "px;\n      min-width: ").concat(width, "px;\n      height: ").concat(height, "px;\n      min-height: ").concat(height, "px;\n      fill: ").concat(colors[color] || color, ";\n    ");
});
export default SvgStyled;
var templateObject_1;
