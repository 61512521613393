var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { Typography, Stack, Divider, FormLabel, Grid, Button, } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import Variable from "./components/Variable";
function Variables(props) {
    var variables = props.variables, pluginIndex = props.pluginIndex, invalidFields = props.invalidFields, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Typography, __assign({ level: "title-sm" }, { children: "Variables" })), _jsxs(Typography, __assign({ level: "body-sm" }, { children: ["Variables are the inputs expected from the user. To handle nested objects, use the syntax", " ", _jsx(Typography, __assign({ variant: "outlined", color: "primary" }, { children: "address__street" })), "."] })), _jsx(Divider, {}), variables.length > 0 && (_jsxs(Grid, __assign({ container: true, spacing: 1.5, alignItems: "center" }, { children: [_jsx(Grid, { xs: 0.5 }), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Name" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Label" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Type" }) })), _jsx(Grid, __assign({ xs: 4.5 }, { children: _jsx(FormLabel, { children: "Question (Optional)" }) })), _jsx(Grid, { xs: 0.5 }), _jsx(Grid, { xs: 0.5 }), variables.map(function (variable, varIndex) { return (_jsx(React.Fragment, { children: _jsx(Variable, { variable: variable, varIndex: varIndex, pluginIndex: pluginIndex, invalidFields: invalidFields, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginDeleteVariable: onPluginDeleteVariable, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue }) }, varIndex)); })] }))), _jsx(Stack, { children: _jsx(Button, __assign({ size: "sm", color: "primary", variant: "plain", startDecorator: _jsx(AddIcon, {}), onClick: function () { return onPluginNewVariable(pluginIndex); }, sx: { alignSelf: "flex-start" } }, { children: "Add new variable" })) })] })));
}
export default memo(Variables);
