/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '../../../../styles/theme';
var primaryStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.white, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.theme, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n\n  & svg {\n    fill: ").concat(colors.white, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.themeSlight, ";\n        color: ").concat(colors.grey60, ";\n        & svg {\n          fill: ").concat(colors.grey60, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.themeDark, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.themeStrong, ";\n        }\n    ") : '', "\n");
};
var secondaryStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.grey190, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.white, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n  border: 1px solid ").concat(colors.borderGray, ";\n\n  & svg {\n    fill: ").concat(colors.grey190, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.grey20, ";\n        border-color: ").concat(colors.none, ";\n        color: ").concat(colors.grey90, ";\n        & svg {\n          fill: ").concat(colors.grey90, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.grey20, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.grey30, ";\n        }\n    ") : '', "\n");
};
var dangerStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.white, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.danger, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n\n  & svg {\n    fill: ").concat(colors.white, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.dangerLight, ";\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.dangerDark, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.dangerStrong, ";\n        }\n    ") : '', "\n");
};
var dangerSecondaryStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.danger, ";\n  height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.white, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n  border: 1px solid ").concat(colors.danger, ";\n\n  & svg {\n    fill: ").concat(colors.danger, ";\n  }\n\n  ").concat(disabled
        ? "\n        border-color: ".concat(colors.dangerLight, ";\n        color: ").concat(colors.dangerLight, ";\n        & svg {\n          fill: ").concat(colors.dangerLight, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          border-color: ".concat(colors.dangerDark, ";\n          color: ").concat(colors.dangerDark, ";\n          & svg {\n            fill: ").concat(colors.dangerDark, ";\n          }\n        }\n        &:active  {\n          border-color: ").concat(colors.dangerStrong, ";\n          color: ").concat(colors.dangerStrong, ";\n          & svg {\n            fill: ").concat(colors.dangerStrong, ";\n          }\n        }\n    ") : '', "\n");
};
var dangerGhostStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.danger, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.none, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n\n  & svg {\n    fill: ").concat(colors.danger, ";\n  }\n\n  ").concat(disabled
        ? "\n        color: ".concat(colors.dangerLight, ";\n        & svg {\n          fill: ").concat(colors.dangerLight, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.grey20, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.grey30, ";\n        }\n    ") : '', "\n");
};
var ghostStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.grey150, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.none, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n\n  & svg {\n    fill: ").concat(colors.grey150, ";\n  }\n\n  ").concat(disabled
        ? "\n        color: ".concat(colors.grey90, ";\n        & svg {\n          fill: ").concat(colors.grey90, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.grey20, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.grey30, ";\n        }\n    ") : '', "\n");
};
var linkStyles = function (_a) {
    var disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  color: ".concat(colors.theme, ";\n  background-color: ").concat(colors.none, ";\n\n  & svg {\n    fill: ").concat(colors.theme, ";\n  }\n\n  ").concat(disabled
        ? "\n        color: ".concat(colors.themeLight, ";\n        & svg {\n          fill: ").concat(colors.themeLight, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          color: ".concat(colors.themeDark, ";\n          & svg {\n            fill: ").concat(colors.themeDark, ";\n          }\n        }\n        &:active  {\n          color: ").concat(colors.themeStrong, ";\n          & svg {\n            fill: ").concat(colors.themeStrong, ";\n          }\n        }\n    ") : '', "\n");
};
var warningStyles = function (_a) {
    var settings = _a.settings, fullWidth = _a.fullWidth, disabled = _a.disabled, isLoading = _a.isLoading;
    return "\n  border-radius: 4px;\n  color: ".concat(colors.white, ";\n  height: ").concat(settings.height, "px;\n  min-height: ").concat(settings.height, "px;\n  min-width: ").concat(settings.minWidth, "px;\n  background-color: ").concat(colors.warning, ";\n  ").concat(fullWidth ? 'width: 100%;' : '', "\n  padding: 0 ").concat(settings.pHorizontal, "px;\n\n  & svg {\n    fill: ").concat(colors.white, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.warningLight, ";\n        color: ").concat(colors.grey60, ";\n        & svg {\n          fill: ").concat(colors.grey60, ";\n        }\n      ")
        : !isLoading ? "\n        &:hover {\n          background-color: ".concat(colors.warning, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.warning, ";\n        }\n    ") : '', "\n");
};
var modeStyles = {
    primary: primaryStyles,
    secondary: secondaryStyles,
    danger: dangerStyles,
    dangerSecondary: dangerSecondaryStyles,
    dangerGhost: dangerGhostStyles,
    ghost: ghostStyles,
    link: linkStyles,
    warning: warningStyles
};
var mainStyles = function (_a) {
    var disabled = _a.disabled, settings = _a.settings;
    return "\n  border: none;\n  outline: none;\n  display: flex;\n  transition: 0.2s;\n  align-items: center;\n  justify-content: center;\n  font-size: ".concat(settings.textSize, "px;\n  cursor: ").concat(disabled ? 'unset' : 'pointer', ";\n");
};
var ButtonStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), mainStyles, function (_a) {
    var mode = _a.mode;
    return modeStyles[mode];
});
export default ButtonStyled;
var templateObject_1;
