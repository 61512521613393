var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { Box, Button, Typography, Card, CardContent, Divider, Stack, } from "@mui/joy";
import { useHubSpot } from "../../data/hooks/useHubSpot";
import { CardActions } from "@mui/material";
export var Integrations = function () {
    var _a = useHubSpot(), hubSpotId = _a.hubSpotId, hubSpotIdLoading = _a.hubSpotIdLoading, getHubSpotConnectUrl = _a.getHubSpotConnectUrl, connectHubSpot = _a.connectHubSpot, getHubSpotIntegration = _a.getHubSpotIntegration;
    useEffect(function () {
        getHubSpotConnectUrl();
        getHubSpotIntegration();
    }, []);
    var connect = function () {
        connectHubSpot();
    };
    var renderHubspotCard = function () { return (_jsxs(Card, __assign({ variant: "outlined", sx: { width: 350 } }, { children: [_jsxs(CardContent, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center" }, { children: [_jsx(FontAwesomeIcon, { icon: faHubspot, size: "lg", style: { marginRight: 8, color: "#F57722" } }), _jsx(Typography, __assign({ level: "h4" }, { children: "HubSpot" }))] })), _jsx(Divider, { sx: { my: 1 } }), _jsx(Typography, { children: "Connect your HubSpot to deploy the AI Agent into HubSpot chat and create tickets as part of the escalation plan." })] }), _jsx(CardActions, { children: _jsx(Button, __assign({ disabled: !!hubSpotId, loading: hubSpotIdLoading, fullWidth: true, onClick: connect }, { children: hubSpotId ? "Connected" : "Connect" })) })] }))); };
    return (_jsx(Box, __assign({ display: "flex", gap: 2, p: 2 }, { children: renderHubspotCard() })));
};
