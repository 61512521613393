/** @format */
// fonts
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import { colors } from '../styles/theme';
export var fonts = {
    regular: {
        name: 'Roboto-Regular',
        file: RobotoRegular
    },
    medium: {
        name: 'Roboto-Medium',
        file: RobotoMedium
    },
};
export var textSizes = {
    12: { fs: 12, lh: 18 },
    14: { fs: 14, lh: 22 },
    16: { fs: 16, lh: 24 },
    18: { fs: 18, lh: 26 },
    20: { fs: 20, lh: 28 },
    28: { fs: 28, lh: 36 },
    32: { fs: 32, lh: 40 },
    42: { fs: 42, lh: 52 }
};
export var mediaQueries = {
    s: '(max-width: 767px)',
    m: '(max-width: 992px)',
    l: '(max-width: 1240px)',
    xl: '(min-width: 1240px)',
};
export var shadows = {
    minimal: '0px 1px 1px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
    splash: '0px 1px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
    dreamy: '0px 2px 20px 0px rgba(0, 0, 0, 0.04), 0px 8px 32px 0px rgba(0, 0, 0, 0.08)',
    longer: '0px 8px 20px 0px rgba(0, 0, 0, 0.06), 0px 24px 60px 0px rgba(0, 0, 0, 0.12)' // Elevation 4
};
export var scrollbars = {
    small: "\n    ::-webkit-scrollbar {\n      width: 4px;\n      height: 4px;\n      background-color: ".concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-track {\n      margin: 3px;\n      background-color: ").concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 100px;\n      background: ").concat(colors.grey90, ";\n    }\n  "),
    smallHorizontal: "\n    ::-webkit-scrollbar {\n      height: 4px;\n      background-color: ".concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-track {\n      margin: 3px;\n      background-color: ").concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 100px;\n      background: ").concat(colors.grey90, ";\n    }\n  "),
    medium: "\n    ::-webkit-scrollbar {\n      width: 8px;\n      background-color: ".concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-track {\n      margin: 5px;\n      background-color: ").concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 100px;\n      background: ").concat(colors.grey90, ";\n    }\n  "),
    large: "\n    ::-webkit-scrollbar {\n      width: 8px;\n      background-color: ".concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-track {\n      margin: 5px;\n      background-color: ").concat(colors.none, ";\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 100px;\n      background: ").concat(colors.grey90, ";\n    }\n  "),
    none: "\n    ::-webkit-scrollbar {\n      width: 0px;\n    }\n\n    ::-webkit-scrollbar-track {\n      margin: 0px;\n    }\n  "
};
