var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useAutoTrainingRecordings } from "../../../data/hooks/useAutoTrainingRecordings";
import { CustomTreeItem } from "../components/TreeViewItem";
import { Box, Typography, Stack, Card, CardActions, CardContent, Divider, Grid, Chip, } from "@mui/joy";
import { experimental_extendTheme as materialExtendTheme, Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID, } from "@mui/material/styles";
import UppyFileUploader from "../../../components/common/UppyFileUploader";
import Skeleton from "react-loading-skeleton";
var materialTheme = materialExtendTheme();
export var AutoTrainingRecordings = function () {
    var _a;
    var _b = useAutoTrainingRecordings(), getRecordings = _b.getRecordings, recordings = _b.recordings, recordingsLoading = _b.recordingsLoading;
    useEffect(function () {
        getRecordings();
    }, []); // Only run once when the component mounts
    function EndIcon() {
        return _jsx("div", { style: { width: 24 } });
    }
    function generateTreeItems(data) {
        if (!Array.isArray(data)) {
            return (_jsx(CustomTreeItem, __assign({ itemId: data.id, label: data.label, labelIcon: data.fileType === "folder" ? FolderIcon : InsertDriveFileIcon, labelInfo: data.state }, { children: data.children && generateTreeItems(data.children) }), data.id));
        }
        return data.map(function (item) { return (_jsx(CustomTreeItem, __assign({ itemId: item.id, label: item.label, labelIcon: item.fileType === "folder" ? FolderIcon : InsertDriveFileIcon, labelInfo: item.state }, { children: item.children && generateTreeItems(item.children) }), item.id)); });
    }
    return (_jsx(Box, { children: _jsxs(Stack, __assign({ spacing: 2, sx: { padding: 2, height: "100vh" } }, { children: [_jsx(Stack, { children: _jsxs(Card, __assign({ variant: "outlined", sx: { width: 350 } }, { children: [_jsxs(CardContent, { children: [_jsx(Stack, __assign({ direction: "row", alignItems: "center" }, { children: _jsxs(Typography, __assign({ level: "h4" }, { children: ["Upload recordings ", _jsx(Chip, __assign({ color: "primary" }, { children: "Beta" }))] })) })), _jsx(Divider, { sx: { my: 1 } }), _jsx(Typography, __assign({ level: "body-md", sx: { mb: 1 } }, { children: "We will use recordings of real conversations to train AI agent, enabling it to learn from actual interactions. This will improve its ability to understand and respond accurately to user inquiries." }))] }), _jsx(CardActions, { children: _jsx(UppyFileUploader, { acceptedFileTypes: [".mp3", "directory"], buttonText: "Upload Recordings", fileActionUrl: "/CallRecordings", onUploadComplete: getRecordings }) })] })) }), _jsx(Stack, __assign({ sx: { flexGrow: 1 } }, { children: recordingsLoading ? (_jsxs(Grid, __assign({ container: true, spacing: 2, sx: { padding: 2, width: "100%" } }, { children: [_jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) })), _jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) })), _jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) })), _jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) })), _jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) })), _jsx(Grid, __assign({ md: 12 }, { children: _jsx(Skeleton, { width: "100%", height: 50 }) }))] }))) : (!recordingsLoading &&
                        (recordings === null || recordings === void 0 ? void 0 : recordings.length) > 0 && (_jsx(MaterialCssVarsProvider, __assign({ theme: (_a = {}, _a[MATERIAL_THEME_ID] = materialTheme, _a) }, { children: _jsx(SimpleTreeView, __assign({ "aria-label": "custom", defaultExpandedItems: ["root"], slots: {
                                expandIcon: ArrowRightIcon,
                                collapseIcon: ArrowDropDownIcon,
                                endIcon: EndIcon,
                            } }, { children: _jsx(CustomTreeItem, __assign({ itemId: "root", label: "Root", labelIcon: FolderIcon }, { children: generateTreeItems(recordings) })) })) })))) }))] })) }));
};
