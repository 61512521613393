var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Skeleton, Box, Typography, List, ListDivider, ListItem, Stack, Chip, Tooltip, } from "@mui/joy";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
export default function AIAgentsList(props) {
    var _a;
    var flows = props.flows, selectedFlowId = props.selectedFlowId, loading = props.loading, onFlowSelect = props.onFlowSelect;
    var _b = React.useState(new Set()), overflowingTextIds = _b[0], setOverflowingTextIds = _b[1];
    var textRefs = React.useRef({});
    var handleItemClick = function (flowId) {
        onFlowSelect(flowId);
    };
    // Function to check overflowing text
    React.useLayoutEffect(function () {
        if (!loading && flows.length > 0) {
            var newOverflowingIds_1 = new Set();
            Object.entries(textRefs.current).forEach(function (_a) {
                var flowId = _a[0], element = _a[1];
                if (element && element.scrollWidth > element.clientWidth) {
                    newOverflowingIds_1.add(flowId);
                }
            });
            setOverflowingTextIds(newOverflowingIds_1);
        }
    }, [flows, loading]); // Run this effect after loading is false and flows are available
    return (_jsx(List, __assign({ sx: (_a = {},
            _a["& .".concat(listItemButtonClasses.root, ".").concat(listItemButtonClasses.selected)] = {
                borderLeft: "2px solid",
                borderLeftColor: "var(--joy-palette-primary-outlinedBorder)",
            },
            _a) }, { children: loading ? (_jsxs(_Fragment, { children: [_jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) })] })) : (flows.map(function (flow, index) { return (_jsxs(React.Fragment, { children: [_jsx(ListItem, { children: _jsx(ListItemButton, __assign({}, (flow.id === selectedFlowId && {
                        selected: true,
                        color: "neutral",
                    }), { sx: { p: 2 }, onClick: function () { return handleItemClick(flow.id); } }, { children: _jsx(Box, __assign({ sx: { width: "100%", maxWidth: "200px" } }, { children: _jsxs("div", { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", sx: {
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                        } }, { children: [_jsx(Tooltip, __assign({ placement: "right", title: flow.name, disableHoverListener: !overflowingTextIds.has(flow.id) }, { children: _jsx(Typography, __assign({ level: "title-sm", sx: {
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "calc(100% - 60px)", // adjust max width to prevent overlap with Chip
                                                    }, ref: function (el) { return (textRefs.current[flow.id] = el); } }, { children: flow.name })) })), flow.isWorkspaceTemplate && (_jsx(Chip, __assign({ size: "sm", color: "warning", variant: "outlined", sx: {
                                                    ml: 1,
                                                    fontSize: "10px",
                                                    height: "20px",
                                                    padding: "0 4px",
                                                } }, { children: "Template" })))] })), _jsx(Typography, __assign({ level: "body-sm" }, { children: flow.flowDescription }))] }) })) })) }), _jsx(ListDivider, { sx: { m: 0 } })] }, index)); })) })));
}
