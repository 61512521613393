var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '../Icon';
import ButtonStyled from './styled/ButtonStyled';
import SpinnerStyled from './styled/SpinnerStyled';
var sizes = ['s', 'm', 'l'];
var modes = ['primary', 'secondary', 'ghost'];
function IconButton(props) {
    var data = props.data, size = props.size, mode = props.mode, icon = props.icon, submit = props.submit, cursor = props.cursor, disabled = props.disabled, isSquare = props.isSquare, isLoading = props.isLoading, className = props.className, stopPropagation = props.stopPropagation, onClick = props.onClick;
    var settings = {
        s: {
            size: 24,
            iconSize: 14
        },
        m: {
            size: 36,
            iconSize: 16
        },
        l: {
            size: 40,
            iconSize: 18
        }
    }[size];
    var _clickHandler = function (event) {
        if (isLoading)
            return;
        if (stopPropagation) {
            event.stopPropagation();
        }
        if (!isLoading && typeof onClick === 'function') {
            onClick(data, event);
        }
    };
    return (_jsx(ButtonStyled, __assign({ mode: mode, cursor: cursor, settings: settings, disabled: disabled, isSquare: isSquare, isLoading: isLoading, className: className, type: submit ? 'submit' : 'button', onClick: _clickHandler }, { children: isLoading ? (_jsx(SpinnerStyled, { children: _jsx(Icon, { name: "loading", size: settings.iconSize, color: mode === 'secondary' ? 'grey190' : 'white' }) })) : (_jsx(Icon, { name: icon, size: settings.iconSize })) })));
}
IconButton.defaultProps = {
    size: 'm',
    data: null,
    submit: false,
    mode: 'primary',
    isLoading: false,
    fullWidth: false,
    stopPropagation: true
};
IconButton.displayName = 'IconButton';
var IconButtonProxy = new Proxy(IconButton, {
    // eslint-disable-next-line react/display-name
    apply: function (Target, args, propsList) {
        return _jsx(Target, __assign({}, propsList[0]));
    },
    get: function (Target, property) {
        // eslint-disable-next-line react/display-name
        if (!modes.includes(property))
            return Target[property];
        var mode = property;
        return new Proxy(Target, {
            // eslint-disable-next-line react/display-name
            apply: function (Target, args, propsList) {
                return _jsx(Target, __assign({}, propsList[0], { mode: mode }));
            },
            get: function (Target, property) {
                if (!sizes.includes(property))
                    return Target[property];
                var size = property;
                // eslint-disable-next-line react/display-name
                return function (props) { return (_jsx(Target, __assign({}, props, { mode: mode, size: size }))); };
            }
        });
    }
});
export default IconButtonProxy;
