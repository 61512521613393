var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Stack, Card, Box, Switch, Alert, Divider, FormLabel, FormControl, Input, Link, CircularProgress, Chip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import InfoIcon from '@mui/icons-material/Info';
import { useHubSpot } from '../../../../../../data/hooks/useHubSpot';
export default function HubSpot(props) {
    var _a, _b, _c, _d;
    var flow = props.flow, invalidFields = props.invalidFields, onHubSpotIsEnabledChange = props.onHubSpotIsEnabledChange, onHubSpotUserIdChange = props.onHubSpotUserIdChange, onHubSpotActivationMessageChange = props.onHubSpotActivationMessageChange;
    var _e = useHubSpot(), hubSpotId = _e.hubSpotId, hubSpotIdLoading = _e.hubSpotIdLoading, getHubSpotIntegration = _e.getHubSpotIntegration;
    useEffect(function () {
        getHubSpotIntegration();
    }, []);
    return (_jsxs(Card, { children: [_jsx(Box, __assign({ sx: { mb: 1 } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }, { children: [_jsxs(Stack, __assign({ spacing: 1, sx: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(FontAwesomeIcon, { icon: faHubspot, size: "lg", style: { marginRight: 8, color: "#F57722" } }), _jsx(Typography, __assign({ level: "title-md" }, { children: "HubSpot Live Chat" })), _jsx(Chip, __assign({ sx: { ml: 1 }, color: "primary" }, { children: "Chat" }))] })), _jsxs(Stack, __assign({ spacing: 2, sx: { pr: 2 } }, { children: [_jsxs(Typography, __assign({ level: "body-sm" }, { children: ["Turn this on to deploy the AI Agent to HubSpot Live Chat. You will need to create a chatflow to enable it.", _jsx(Link, __assign({ sx: { ml: 1 } }, { children: "Learn More" }))] })), !hubSpotId && !hubSpotIdLoading && _jsx(Alert, __assign({ variant: "soft", color: "warning", startDecorator: _jsx(InfoIcon, {}) }, { children: _jsxs(Typography, __assign({ level: "body-sm" }, { children: ["First, connect your HubSpot account from the integrations page.", _jsx(Link, __assign({ sx: { ml: 1 }, component: RouterLink, to: "/integrations" }, { children: "Integrations" }))] })) }))] }))] })), hubSpotIdLoading ?
                            _jsx(CircularProgress, { size: 'sm' })
                            :
                                (hubSpotId && _jsx(Switch, { checked: (_a = flow.hubSpotDeploymentConfig) === null || _a === void 0 ? void 0 : _a.isEnabled, onChange: onHubSpotIsEnabledChange, size: "lg", variant: "outlined" }))] })) })), ((_b = flow.hubSpotDeploymentConfig) === null || _b === void 0 ? void 0 : _b.isEnabled) &&
                _jsxs(_Fragment, { children: [_jsx(Alert, __assign({ variant: "soft", color: "warning", startDecorator: _jsx(InfoIcon, {}) }, { children: "Please note, only one AI agent can be deployed to HubSpot simultaneously." })), _jsx(Divider, {}), _jsxs(Stack, __assign({ spacing: 2, sx: { my: 1 } }, { children: [_jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(FormLabel, { children: "Dowork User Id in HubSpot" }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Create a Dowork.ai user in HubSpot, then enter the user ID here to deploy the AI Agent." })), _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "HubSpot User Id", error: invalidFields.some(function (e) { return e.field === 'hubSpotDeploymentConfig.doworkUserIdInHubspot'; }), value: (_c = flow.hubSpotDeploymentConfig) === null || _c === void 0 ? void 0 : _c.doworkUserIdInHubspot, onChange: onHubSpotUserIdChange }) }))] })), _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(FormLabel, { children: "HubSpot Activation Bubble Text" }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Set the text you want to appear on the bubble that will activate the AI Agent." })), _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Activation Bubble Text", error: invalidFields.some(function (e) { return e.field === 'hubSpotDeploymentConfig.activationMessage'; }), value: (_d = flow.hubSpotDeploymentConfig) === null || _d === void 0 ? void 0 : _d.activationMessage, onChange: onHubSpotActivationMessageChange }) }))] }))] }))] })] }));
}
