/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { other, marginPadding } from './common';
import { setResponsiveStyles } from '../../../utils/style';
var setStyles = function (props) { return "\n  ".concat(other(props), "\n  ").concat(marginPadding(props), "\n"); };
var Text = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), setStyles, function (props) { return setResponsiveStyles(props, setStyles); });
export default Text;
var templateObject_1;
