import { jsx as _jsx } from "react/jsx-runtime";
import Avatar from '@mui/joy/Avatar';
export default function AvatarWithStatus(props) {
    var src = props.src, online = props.online, _a = props.size, size = _a === void 0 ? 'md' : _a;
    return (
    // <Badge
    //   variant="solid"
    //   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //   badgeInset="14%"
    //   color={online ? 'success' : 'neutral'}
    // >
    _jsx(Avatar, { src: src, size: size })
    // </Badge>
    );
}
