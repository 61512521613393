import { useRecoilState } from 'recoil';
import { useRequest } from '../../hooks/useRequest';
import { useState } from 'react';
import { analyzedContentAtom, analyzedContentLoadingAtom } from '../store';
export var useAnalyzedContent = function () {
    var request = useRequest();
    var _a = useRecoilState(analyzedContentAtom), analyzedContent = _a[0], setAnalyzedContent = _a[1];
    var _b = useRecoilState(analyzedContentLoadingAtom), analyzedContentLoading = _b[0], setAnalyzedContentLoading = _b[1];
    var _c = useState(false), isDeleting = _c[0], setIsDeleting = _c[1];
    var _d = useState(false), isUpdating = _d[0], setIsUpdating = _d[1];
    var getAnalyzedContent = function () {
        setAnalyzedContentLoading(true);
        request.get('/Crawling/analyzedContent')
            .then(function (_a) {
            var data = _a.data;
            return setAnalyzedContent(data);
        })
            .catch(console.log)
            .finally(function () { return setAnalyzedContentLoading(false); });
    };
    var handleDeletePage = function (id) {
        setIsDeleting(true); // Set loading state
        request.delete("/Crawling/Page/".concat(id))
            .then(function () {
            var updatedContent = (analyzedContent === null || analyzedContent === void 0 ? void 0 : analyzedContent.filter(function (item) { return item.id !== id; })) || [];
            setAnalyzedContent(updatedContent);
        })
            .catch(console.log)
            .finally(function () { return setIsDeleting(false); }); // Reset loading state
    };
    var handleUpdatePage = function (id) {
        setIsUpdating(true); // Set loading state
        request.put("/Crawling/Page/".concat(id))
            .then(function () {
            alert("Successfully updated");
        })
            .catch(function (_a) {
            var response = _a.response;
            if (response.data) {
                // @ts-ignore
                alert(response.data, { type: 'danger' });
            }
        })
            .finally(function () { return setIsUpdating(false); }); // Reset loading state
    };
    return { getAnalyzedContent: getAnalyzedContent, handleDeletePage: handleDeletePage, handleUpdatePage: handleUpdatePage, analyzedContent: analyzedContent, analyzedContentLoading: analyzedContentLoading, isDeleting: isDeleting, isUpdating: isUpdating };
};
