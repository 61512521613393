var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import View from '../../components/styledComponents/View';
import Text from '../../components/styledComponents/Text';
import Icon from '../../components/ui/Icon';
import Menu from '../../components/ui/Menu';
import Table from '../../components/ui/Table';
import Button from '../../components/ui/Button';
import IconButton from '../../components/ui/IconButton';
import Container from '../../components/ui/Container';
import UploadInputStyled from './styled/UploadInputStyled';
import { useUploads } from '../../data/hooks/useUploads';
var StateEnum;
(function (StateEnum) {
    StateEnum["isDeleting"] = "Deleting...";
    StateEnum["isInvalid"] = "Failed to process";
    StateEnum["isProcessed"] = "Ready";
    StateEnum["isLimitExceeded"] = "Limit Exceeded";
    StateEnum["isProcessing"] = "Ready to process";
})(StateEnum || (StateEnum = {}));
var StateColorEnum;
(function (StateColorEnum) {
    StateColorEnum["isDeleting"] = "dangerStrong";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    StateColorEnum["isInvalid"] = "dangerStrong";
    StateColorEnum["isProcessed"] = "success";
    StateColorEnum["isLimitExceeded"] = "warning";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    StateColorEnum["isProcessing"] = "warning";
})(StateColorEnum || (StateColorEnum = {}));
export var KnowledgeBaseFiles = function () {
    var inputRef = useRef(null);
    var _a = useUploads(), getUploads = _a.getUploads, uploadFile = _a.uploadFile, deleteUpload = _a.deleteUpload, uploads = _a.uploads, uploadsLoading = _a.uploadsLoading, uploadFileLoading = _a.uploadFileLoading;
    var menuContent = function (fileId) {
        return (_jsx(View, __assign({ onClick: function (e) { return e.stopPropagation(); }, padding: { vertical: 4 } }, { children: _jsxs(View, __assign({ flex: true, aCenter: true, padding: { horizontal: 12, vertical: 10 }, cursor: "pointer", hoverBg: "grey10", onClick: function () { return deleteUpload(fileId); } }, { children: [_jsx(Icon, { name: "deleteBin", size: 18, color: "grey90" }), _jsx(Text, __assign({ typo: "14/grey130", margin: { left: 12 } }, { children: "Delete" }))] })) })));
    };
    var returnState = function (_a) {
        var isDeleting = _a.isDeleting, isInvalid = _a.isInvalid, isProcessed = _a.isProcessed, isLimitExceeded = _a.isLimitExceeded, isProcessing = _a.isProcessing;
        if (isDeleting)
            return StateEnum.isDeleting;
        else if (isInvalid)
            return StateEnum.isInvalid;
        else if (isProcessed)
            return StateEnum.isProcessed;
        else if (isLimitExceeded)
            return StateEnum.isLimitExceeded;
        else if (isProcessing)
            return StateEnum.isProcessing;
    };
    var returnColor = function (_a) {
        var isDeleting = _a.isDeleting, isInvalid = _a.isInvalid, isProcessed = _a.isProcessed, isLimitExceeded = _a.isLimitExceeded, isProcessing = _a.isProcessing;
        if (isDeleting)
            return StateColorEnum.isDeleting;
        else if (isInvalid)
            return StateColorEnum.isInvalid;
        else if (isProcessed)
            return StateColorEnum.isProcessed;
        else if (isLimitExceeded)
            return StateColorEnum.isLimitExceeded;
        else if (isProcessing)
            return StateColorEnum.isProcessing;
    };
    var fields = [
        { name: 'fileDisplayName', title: 'Name' },
        {
            name: 'state',
            title: 'Status',
            width: 180,
            render: function (data) { return (_jsxs(View, __assign({ flex: true, aCenter: true }, { children: [_jsx(View, { width: 8, height: 8, radius: 8, bgColor: returnColor(data) }), _jsx(Text, __assign({ margin: { left: 13 }, typo: "14/grey190" }, { children: returnState(data) }))] }))); }
        },
        {
            name: '',
            title: '',
            width: 70,
            render: function (_a) {
                var fileId = _a.fileId;
                return (_jsx(View, __assign({ flex: true, aCenter: true }, { children: _jsx(Menu, __assign({ name: "avatarMenu", width: 176, placement: "bottom-end", renderContent: function () { return menuContent(fileId); } }, { children: _jsx(IconButton.ghost, { icon: "actions", isSquare: true, stopPropagation: false }) })) })));
            }
        },
    ];
    useEffect(function () {
        getUploads();
    }, []);
    var onUpload = function () {
        // @ts-ignore
        inputRef.current.click();
    };
    var onFileChange = function (e) {
        var _a;
        var file = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        var formData = new FormData();
        formData.append('file', file);
        e.target.value = '';
        uploadFile(formData);
    };
    return (_jsxs(View, __assign({ gap: 20, padding: { vertical: 20, left: 10, right: 20 }, overflow: "auto" }, { children: [_jsxs(Container, __assign({ flex: true, aCenter: true, between: true, padding: { vertical: 12, right: 12, left: 32 } }, { children: [_jsx(UploadInputStyled, { type: "file", ref: inputRef, onChange: onFileChange }), _jsx(Text, __assign({ typo: "14/grey190medium" }, { children: "Uploads" })), _jsx(Button, { iconLeft: "add", label: "Upload File", onClick: onUpload, isLoading: uploadFileLoading })] })), !uploadsLoading && !(uploads === null || uploads === void 0 ? void 0 : uploads.length) ? (_jsxs(Container, __assign({ flex: true, column: true, aCenter: true, jCenter: true, padding: { vertical: 124 } }, { children: [_jsx(Icon, { name: "empty", color: "grey50", size: 48 }), _jsx(Text, __assign({ typo: "18/grey130", margin: { vertical: 16 } }, { children: "No Uploads yet." }))] }))) : (_jsx(Table, { fields: fields, items: uploads, isLoading: uploadsLoading }))] })));
};
