var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import View from '../../styledComponents/View';
import Text from '../../styledComponents/Text';
import NowrapText from '../../styledComponents/NowrapText';
import Icon from '../../ui/Icon';
import { TableStyled, TableHeaderStyled, HeaderColumnStyled, TableBodyStyled, RowStyled, ColumnStyled } from './styled/TableStyled';
var Table = function (_a) {
    var _b;
    var fields = _a.fields, items = _a.items, isLoading = _a.isLoading, onGroupOpen = _a.onGroupOpen;
    var _c = useState([]), openedGroups = _c[0], setOpenedGroups = _c[1];
    var groupToggle = function (item, index) {
        if (openedGroups.includes(index)) {
            setOpenedGroups(openedGroups.filter(function (i) { return i !== index; }));
        }
        else {
            setOpenedGroups(__spreadArray(__spreadArray([], openedGroups, true), [index], false));
            onGroupOpen === null || onGroupOpen === void 0 ? void 0 : onGroupOpen(item);
        }
    };
    var renderColumn = function (_a) {
        var item = _a.item, field = _a.field, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex, isSub = _a.isSub;
        var isOpen = openedGroups.includes(rowIndex);
        return (_jsxs(ColumnStyled, __assign({ align: field.align, width: field === null || field === void 0 ? void 0 : field.width }, { children: [columnIndex === 0 && (_jsx(View, __assign({ flex: true, aCenter: true, jCenter: true, minW: 24, width: 24, height: 24, cursor: "pointer", padding: { horizontal: 4 }, visibility: !item.isGroup ? 'hidden' : '', onClick: function () { return groupToggle(item, rowIndex); } }, { children: _jsx(Icon, { name: isOpen ? 'reduce' : 'toRight', color: "grey90", size: 14 }) }))), isLoading ? (_jsx(Skeleton, { width: (field === null || field === void 0 ? void 0 : field.width) ? field.width - 40 : 150 })) : field.render ? field.render(item, isSub) : (_jsx(NowrapText, __assign({ typo: "14/grey190" }, { children: "".concat(item[field.name] || '') }))), columnIndex === 0 && (_jsx(View, { children: item.isGroup && (_jsx(View, __assign({ flex: true, aCenter: true, margin: { horizontal: 4 } }, { children: _jsx(Icon, { name: isOpen ? 'groupOpen' : 'groupClose', size: 16, color: "themeStrong" }) }))) }))] }), "".concat(rowIndex).concat(columnIndex)));
    };
    return (_jsx(View, __assign({ radius: 4, shadow: "minimal", bgColor: "grey10", overflow: "hidden", border: "1px solid", borderColor: "borderLight" }, { children: _jsxs(TableStyled, { children: [_jsx(TableHeaderStyled, { children: fields === null || fields === void 0 ? void 0 : fields.map(function (field) { return (_jsx(HeaderColumnStyled, __assign({ align: field.align, width: field === null || field === void 0 ? void 0 : field.width }, { children: isLoading ? (_jsx(Skeleton, { width: (field === null || field === void 0 ? void 0 : field.width) ? field.width - 40 : 100 })) : (_jsx(Text, __assign({ typo: "14/grey190/medium" }, { children: field.title }))) }), field.name)); }) }), _jsx(TableBodyStyled, { children: (_b = (isLoading ? [{}, {}, {}, {}, {}, {}] : items)) === null || _b === void 0 ? void 0 : _b.map(function (item, rowIndex) {
                        var _a;
                        return (_jsxs(_Fragment, { children: [_jsx(RowStyled, { children: fields === null || fields === void 0 ? void 0 : fields.map(function (field, columnIndex) { return renderColumn({ item: item, field: field, rowIndex: rowIndex, columnIndex: columnIndex }); }) }, rowIndex), 
                                // @ts-ignore
                                openedGroups.includes(rowIndex) && ((_a = item === null || item === void 0 ? void 0 : item.items) === null || _a === void 0 ? void 0 : _a.map(function (item, rowIndex) { return (_jsx(RowStyled, { children: fields === null || fields === void 0 ? void 0 : fields.map(function (field, columnIndex) { return renderColumn({ item: item, field: field, rowIndex: rowIndex, columnIndex: columnIndex, isSub: true }); }) }, rowIndex)); }))] }));
                    }) })] }) })));
};
export default Table;
