export var FilterEnums;
(function (FilterEnums) {
    FilterEnums["date"] = "date";
    FilterEnums["userIds"] = "userIds";
    FilterEnums["resolution"] = "resolution";
    FilterEnums["feedback"] = "feedback";
    FilterEnums["today"] = "today";
    FilterEnums["yesterday"] = "yesterday";
    FilterEnums["thisWeek"] = "thisWeek";
    FilterEnums["lastWeek"] = "lastWeek";
    FilterEnums["thisMoth"] = "thisMonth";
    FilterEnums["lastMonth"] = "LastMonth";
    FilterEnums["last60Days"] = "last60Days";
    FilterEnums["last90Days"] = "last90Days";
    FilterEnums["resolved"] = "resolved";
    FilterEnums["ticketCreated"] = "tickedCreated";
    FilterEnums["liked"] = "liked";
    FilterEnums["disliked"] = "disliked";
    FilterEnums["clear"] = "clear";
})(FilterEnums || (FilterEnums = {}));
