var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton, Box } from "@mui/joy";
var ChatSkeleton = function () {
    return (_jsxs(Box, __assign({ sx: {
            width: "100%",
            p: 2,
            display: "flex",
            flexDirection: "column",
        } }, { children: [_jsx(Box, __assign({ sx: { mb: 2 } }, { children: _jsx(Skeleton, { variant: "rectangular", width: "30%", height: 40 }) })), _jsx(Box, __assign({ sx: {
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    overflowY: "auto", // Optional for scrolling when chat is long
                } }, { children: __spreadArray([], Array(9), true).map(function (_, index) { return (_jsx(Box, __assign({ sx: { mt: 2 } }, { children: index % 2 === 0 ? (
                    /* AI Agent's message on the left */
                    _jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { ml: 6 } }, { children: _jsx(Skeleton, { variant: "text", width: "20%", height: 20 }) })), _jsxs(Box, __assign({ sx: { mt: 1, mb: 1, display: "flex", alignItems: "center" } }, { children: [_jsx(Skeleton, { variant: "circular", width: 40, height: 40, sx: { mr: 1 } }), _jsx(Skeleton, { variant: "rectangular", width: "50%", height: 40 })] }))] })) : (
                    /* User's message on the right */
                    _jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { display: "flex", justifyContent: "flex-end", textAlign: "right", mr: 6 } }, { children: _jsx(Skeleton, { variant: "text", width: "20%", height: 20 }) })), _jsxs(Box, __assign({ sx: { mt: 1, display: "flex", justifyContent: "flex-end" } }, { children: [_jsx(Skeleton, { variant: "rectangular", width: "50%", height: 40 }), _jsx(Skeleton, { variant: "circular", width: 40, height: 40, sx: { ml: 1 } })] }))] })) }), index)); }) }))] })));
};
export default ChatSkeleton;
