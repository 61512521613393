var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/joy';
import HubSpot from './components/HubSpot';
import Twilio from './components/Twilio';
import SIP from './components/SIP';
import { useWebsite } from '../../../../data/hooks/useWebsite';
import { Upgrade } from '../../../../components/common/Upgrade';
export default function Deployment(props) {
    var flow = props.flow, invalidFields = props.invalidFields, onError = props.onError, onHubSpotIsEnabledChange = props.onHubSpotIsEnabledChange, onHubSpotUserIdChange = props.onHubSpotUserIdChange, onHubSpotActivationMessageChange = props.onHubSpotActivationMessageChange, onSIPConfigurationUpdate = props.onSIPConfigurationUpdate, onSIPIsEnabledChange = props.onSIPIsEnabledChange;
    var limitation = useWebsite().limitation;
    return (_jsx(_Fragment, { children: limitation &&
            _jsx(Stack, __assign({ spacing: 2, sx: { flexGrow: 1 } }, { children: limitation.isPaid ?
                    _jsxs(_Fragment, { children: [_jsx(SIP, { flow: flow, onError: onError, onSIPIsEnabledChange: onSIPIsEnabledChange, onSIPConfigurationUpdate: onSIPConfigurationUpdate }), _jsx(Twilio, { flow: flow }), _jsx(HubSpot, { flow: flow, invalidFields: invalidFields, onHubSpotIsEnabledChange: onHubSpotIsEnabledChange, onHubSpotActivationMessageChange: onHubSpotActivationMessageChange, onHubSpotUserIdChange: onHubSpotUserIdChange })] })
                    :
                        _jsxs(Stack, __assign({ spacing: 2, direction: "column", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Typography, __assign({ level: "h3" }, { children: "Upgrade to deploy" })), _jsx(Upgrade, { workspaceId: limitation.workspaceId })] })) })) }));
}
