/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../View';
import { colors } from '../../../styles/theme';
import { parseTypography } from '../../../utils/style';
var NowrapText = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ", "\n  ", "\n"], ["\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  ", "\n  ", "\n"])), function (_a) {
    var size = _a.size, _b = _a.color, color = _b === void 0 ? 'dark' : _b, _c = _a.line, line = _c === void 0 ? 1 : _c, center = _a.center;
    return "\n    font-size: ".concat(size || 14, "px;\n    color: ").concat(colors[color], ";\n    ").concat(center ? 'text-align: center;' : '', "\n\n    ").concat(line === 1
        ? "\n          white-space: nowrap;\n        "
        : "\n          display: -webkit-box;\n          -webkit-box-orient: vertical;\n          -webkit-line-clamp: ".concat(line, ";\n        "), "\n  ");
}, function (_a) {
    var typo = _a.typo;
    return (typo ? parseTypography(typo) : '');
});
export default NowrapText;
var templateObject_1;
