var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon';
import View from '../../styledComponents/View';
import Text from '../../styledComponents/Text';
import ButtonStyled from './styled/ButtonStyled';
import SpinnerStyled from './styled/SpinnerStyled';
var sizes = ['s', 'm', 'l'];
var modes = [
    'primary',
    'secondary',
    'danger',
    'dangerSecondary',
    'dangerGhost',
    'ghost',
    'link',
    'warning'
];
function Button(props) {
    var data = props.data, size = props.size, mode = props.mode, label = props.label, submit = props.submit, minWidth = props.minWidth, disabled = props.disabled, iconLeft = props.iconLeft, iconRight = props.iconRight, fullWidth = props.fullWidth, isLoading = props.isLoading, className = props.className, stopPropagation = props.stopPropagation, children = props.children, onClick = props.onClick;
    var settings = {
        s: {
            height: 24,
            textSize: 12,
            iconSize: 14,
            pHorizontal: 4,
            minWidth: minWidth || 54
        },
        m: {
            height: 36,
            textSize: 14,
            iconSize: 16,
            pHorizontal: 8,
            minWidth: minWidth || 82
        },
        l: {
            height: 40,
            textSize: 16,
            iconSize: 18,
            pHorizontal: 8,
            minWidth: minWidth || 90
        }
    }[size];
    var _clickHandler = function (event) {
        if (stopPropagation) {
            event.stopPropagation();
        }
        if (!isLoading && typeof onClick === 'function') {
            onClick(data, event);
        }
    };
    return (_jsx(ButtonStyled, __assign({ mode: mode, settings: settings, disabled: disabled, fullWidth: fullWidth, isLoading: isLoading, className: className, onClick: _clickHandler, type: submit ? 'submit' : 'button' }, { children: children ? (children) : isLoading ? (_jsx(SpinnerStyled, { children: _jsx(Icon, { name: "loading", size: settings.iconSize, color: mode === 'secondary' ? 'grey190' : 'white' }) })) : (_jsxs(_Fragment, { children: [!!iconLeft && (_jsx(View, __assign({ flex: true, aCenter: true, margin: { right: 4 } }, { children: _jsx(Icon, { name: iconLeft, size: settings.iconSize }) }))), _jsx(Text, { children: label }), !!iconRight && (_jsx(View, __assign({ flex: true, aCenter: true, margin: { left: 4 } }, { children: _jsx(Icon, { name: iconRight, size: settings.iconSize }) })))] })) })));
}
Button.defaultProps = {
    size: 'm',
    data: null,
    submit: false,
    mode: 'primary',
    isLoading: false,
    fullWidth: false,
    stopPropagation: true
};
Button.displayName = 'Button';
var ButtonProxy = new Proxy(Button, {
    // eslint-disable-next-line react/display-name
    apply: function (Target, args, propsList) {
        return _jsx(Target, __assign({}, propsList[0]));
    },
    get: function (Target, property) {
        // eslint-disable-next-line react/display-name
        if (!modes.includes(property))
            return Target[property];
        var mode = property;
        return new Proxy(Target, {
            // eslint-disable-next-line react/display-name
            apply: function (Target, args, propsList) {
                return _jsx(Target, __assign({}, propsList[0], { mode: mode }));
            },
            get: function (Target, property) {
                if (!sizes.includes(property))
                    return Target[property];
                var size = property;
                // eslint-disable-next-line react/display-name
                return function (props) { return _jsx(Target, __assign({}, props, { mode: mode, size: size })); };
            }
        });
    }
});
export default ButtonProxy;
