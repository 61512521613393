var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
export default function ChatBubble(props) {
    var content = props.content, variant = props.variant, timestamp = props.timestamp, sender = props.sender;
    var isSent = variant === "sent";
    return (_jsxs(Box, __assign({ sx: [
            {
                maxWidth: "60%",
                minWidth: "auto",
            },
            isSent && {
                mr: "16px!important",
            },
        ] }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { justifyContent: "space-between", mb: 0.25 } }, { children: [_jsx(Typography, __assign({ level: "body-xs" }, { children: sender.name })), _jsx(Typography, __assign({ level: "body-xs" }, { children: timestamp }))] })), _jsx(Box, __assign({ sx: { position: "relative" } }, { children: _jsx(Sheet, __assign({ color: isSent ? "primary" : "neutral", variant: isSent ? "solid" : "soft", sx: [
                        {
                            p: 1.25,
                            borderRadius: "lg",
                        },
                        isSent
                            ? {
                                borderTopRightRadius: 0,
                            }
                            : {
                                borderTopRightRadius: "lg",
                            },
                        isSent
                            ? {
                                borderTopLeftRadius: "lg",
                            }
                            : {
                                borderTopLeftRadius: 0,
                            },
                        isSent
                            ? {
                                backgroundColor: "var(--joy-palette-primary-solidBg)",
                            }
                            : {
                                backgroundColor: "background.body",
                            },
                    ] }, { children: _jsx(Typography, __assign({ level: "body-sm", sx: [
                            isSent
                                ? {
                                    color: "var(--joy-palette-common-white)",
                                }
                                : {
                                    color: "var(--joy-palette-text-primary)",
                                },
                        ] }, { children: content })) })) }))] })));
}
