var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
import { colors } from '../../../../styles/theme';
var ItemStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 36px;\n  display: flex;\n  cursor: pointer;\n  overflow: hidden;\n  border-radius: 4px;\n  position: relative;\n  align-items: center;\n\n  transition: 0.1s;\n\n  color: ", ";\n\n  ", "\n"], ["\n  height: 36px;\n  display: flex;\n  cursor: pointer;\n  overflow: hidden;\n  border-radius: 4px;\n  position: relative;\n  align-items: center;\n\n  transition: 0.1s;\n\n  color: ", ";\n\n  ", "\n"])), colors.grey130, function (_a) {
    var active = _a.active, index = _a.index;
    return "\n    background-color: ".concat(active ? colors.themeSlight : colors.white, ";\n    margin-top: ").concat(index === 0 ? 0 : 2, "px;\n\n    &:hover {\n      background-color: ").concat(active ? colors.themeSlight : colors.grey10, ";\n    }\n  ");
});
export default ItemStyled;
var templateObject_1;
