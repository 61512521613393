/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '../../../../styles/theme';
var primaryStyles = function (_a) {
    var disabled = _a.disabled;
    return "\n  background-color: ".concat(colors.theme, ";\n\n  & svg {\n    fill: ").concat(colors.white, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.themeLight, ";\n      ")
        : "\n        &:hover {\n          background-color: ".concat(colors.themeDark, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.themeStrong, ";\n        }\n    "), "\n");
};
var secondaryStyles = function (_a) {
    var disabled = _a.disabled;
    return "\n  background-color: ".concat(colors.white, ";\n  border: 1px solid ").concat(colors.borderLight, ";\n\n  & svg {\n    fill: ").concat(colors.grey190, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.white, ";\n        border-color: ").concat(colors.none, ";\n        & svg {\n          fill: ").concat(colors.grey50, ";\n        }\n      ")
        : "\n        &:hover {\n          background-color: ".concat(colors.grey20, ";\n          & svg {\n            fill: ").concat(colors.grey130, ";\n          }\n        }\n        &:active  {\n          background-color: ").concat(colors.grey30, ";\n          & svg {\n            fill: ").concat(colors.grey130, ";\n          }\n        }\n    "), "\n");
};
var ghostStyles = function (_a) {
    var disabled = _a.disabled;
    return "\n  background-color: ".concat(colors.none, ";\n\n  & svg {\n    fill: ").concat(colors.grey130, ";\n  }\n\n  ").concat(disabled
        ? "\n        background-color: ".concat(colors.none, ";\n        & svg {\n          fill: ").concat(colors.grey50, ";\n        }\n      ")
        : "\n        &:hover {\n          background-color: ".concat(colors.grey20, ";\n        }\n        &:active  {\n          background-color: ").concat(colors.grey30, ";\n        }\n    "), "\n");
};
var modeStyles = {
    primary: primaryStyles,
    secondary: secondaryStyles,
    ghost: ghostStyles
};
var mainStyles = function (_a) {
    var disabled = _a.disabled, isSquare = _a.isSquare, settings = _a.settings, cursor = _a.cursor;
    return "\n  border: none;\n  outline: none;\n  display: flex;\n  transition: 0.2s;\n  align-items: center;\n  justify-content: center;\n  width: ".concat(settings.size, "px;\n  height: ").concat(settings.size, "px;\n  cursor: ").concat(disabled ? 'auto' : cursor ? cursor : 'pointer', ";\n  border-radius: ").concat(isSquare ? '4px' : '50%', ";\n");
};
var ButtonStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), mainStyles, function (_a) {
    var mode = _a.mode;
    return modeStyles[mode];
});
export default ButtonStyled;
var templateObject_1;
