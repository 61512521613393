/** @format */
import { colors } from '../styles/theme';
import { fonts, textSizes, mediaQueries } from '../styles/typography';
export var addMeasure = function (value, measure) {
    if (measure === void 0) { measure = 'px'; }
    if (typeof value === 'number') {
        return "".concat(value).concat(measure);
    }
    return value;
};
export var marginPaddingParser = function (value, measure) {
    if (measure === void 0) { measure = 'px'; }
    if (typeof value === 'number') {
        return "".concat(value).concat(measure);
    }
    else if (typeof value === 'object') {
        var _a = value.top, top_1 = _a === void 0 ? 0 : _a, _b = value.left, left = _b === void 0 ? 0 : _b, _c = value.right, right = _c === void 0 ? 0 : _c, _d = value.bottom, bottom = _d === void 0 ? 0 : _d, _e = value.vertical, vertical = _e === void 0 ? 0 : _e, _f = value.horizontal, horizontal = _f === void 0 ? 0 : _f;
        return "".concat(addMeasure(vertical || top_1, measure), " ").concat(addMeasure(horizontal || right, measure), " ").concat(addMeasure(vertical || bottom, measure), " ").concat(addMeasure(horizontal || left, measure));
    }
    return value;
};
export var parseFonts = function (fonts) {
    return Object.values(fonts).reduce(function (value, font) { return "\n      ".concat(value, "\n\n      @font-face {\n        font-family: '").concat(font.name, "';\n        src: url(").concat(font.file, ");\n      }\n    "); }, '');
};
export var parseTypography = function (typo) {
    var _a = typo.split('/'), _b = _a[0], size = _b === void 0 ? 14 : _b, _c = _a[1], color = _c === void 0 ? 'defaultTextColor' : _c, _d = _a[2], font = _d === void 0 ? 'regular' : _d;
    var _e = textSizes[size], fs = _e.fs, lh = _e.lh;
    var textColor = colors[color];
    var fontName = fonts[font].name;
    return "\n    font-size: ".concat(fs, "px;\n    line-height: ").concat(lh, "px;\n    color: ").concat(textColor, ";\n    font-family: ").concat(fontName, ", sans-serif;\n  ");
};
export var setResponsiveStyles = function (props, setStyles) {
    var responsiveProps = Object.entries(props).filter(function (prop) { return prop[0].includes('_'); });
    if (!responsiveProps.length)
        return '';
    var query = {};
    responsiveProps.forEach(function (item) {
        var queryName = item[0].slice(item.indexOf('_'));
        var propName = item[0].slice(0, item.indexOf('_') - 1);
        if (!query[queryName])
            query[queryName] = {};
        query[queryName][propName] = item[1];
    });
    return Object.entries(query).map(function (item) { return "\n    @media ".concat(mediaQueries[item[0]], "{\n      ").concat(setStyles(item[1]), "\n    }\n  "); }).join('');
};
