/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import View from '../../../styledComponents/View';
var rotate = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  to { transform: rotate(360deg); }\n"], ["\n  to { transform: rotate(360deg); }\n"])));
var SpinnerStyled = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  animation-name: ", ";\n  animation-duration: 2s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n"], ["\n  display: flex;\n  align-items: center;\n  animation-name: ", ";\n  animation-duration: 2s;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n"])), rotate);
export default SpinnerStyled;
var templateObject_1, templateObject_2;
