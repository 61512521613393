var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Grid, Input, IconButton, Stack, FormLabel, } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
var TemplateMetadataEditor = function (_a) {
    var templateMetadata = _a.templateMetadata, onAddNew = _a.onAddNew, onDeleteKey = _a.onDeleteKey, onUpdateValue = _a.onUpdateValue;
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [templateMetadata && Object.keys(templateMetadata).length > 0 && (_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(FormLabel, { children: "Key" }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(FormLabel, { children: "Value" }) })), _jsx(Grid, { xs: 1 }), Object.entries(templateMetadata).map(function (_a, index) {
                        var key = _a[0], value = _a[1];
                        return (_jsxs(React.Fragment, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(Input, { size: "sm", value: key, onChange: function (event) {
                                            return onUpdateValue(key, event.target.value, "key");
                                        } }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(Input, { size: "sm", value: value, onChange: function (event) {
                                            return onUpdateValue(key, event.target.value, "value");
                                        } }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(IconButton, __assign({ size: "sm", onClick: function () { return onDeleteKey(key); } }, { children: _jsx(DeleteIcon, {}) })) }))] }, index));
                    })] }))), _jsx(Button, __assign({ size: "sm", color: "primary", variant: "plain", startDecorator: _jsx(AddIcon, {}), onClick: onAddNew, sx: { alignSelf: "flex-start" } }, { children: "Add new pair" }))] })));
};
export default TemplateMetadataEditor;
