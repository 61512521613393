import React from 'react';
var useIsScrolling = function () {
    var _a = React.useState(null), scrollContainerRef = _a[0], setScrollContainerRef = _a[1];
    var _b = React.useState(false), isScrolling = _b[0], setIsScrolling = _b[1];
    var handleRef = function (ref) {
        if (!ref)
            return;
        setScrollContainerRef(ref);
        setIsScrolling(scrollContainerRef && scrollContainerRef ? (scrollContainerRef === null || scrollContainerRef === void 0 ? void 0 : scrollContainerRef.clientHeight) < (scrollContainerRef === null || scrollContainerRef === void 0 ? void 0 : scrollContainerRef.scrollHeight) : false);
        var resizeObserver = new ResizeObserver(function () {
            setIsScrolling(scrollContainerRef && scrollContainerRef ? (scrollContainerRef === null || scrollContainerRef === void 0 ? void 0 : scrollContainerRef.clientHeight) < (scrollContainerRef === null || scrollContainerRef === void 0 ? void 0 : scrollContainerRef.scrollHeight) : false);
        });
        resizeObserver.observe(ref);
    };
    return { handleRef: handleRef, isScrolling: isScrolling, ref: scrollContainerRef };
};
export default useIsScrolling;
