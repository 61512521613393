var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import IconButton from '@mui/joy/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
export default function ColorSchemeToggle(props) {
    var onClick = props.onClick, sx = props.sx, other = __rest(props, ["onClick", "sx"]);
    var _a = useColorScheme(), mode = _a.mode, setMode = _a.setMode;
    var _b = React.useState(false), mounted = _b[0], setMounted = _b[1];
    React.useEffect(function () {
        setMounted(true);
    }, []);
    if (!mounted) {
        return (_jsx(IconButton, __assign({ size: "sm", variant: "outlined", color: "neutral" }, other, { sx: sx, disabled: true })));
    }
    return (_jsxs(IconButton, __assign({ id: "toggle-mode", size: "sm", variant: "outlined", color: "neutral" }, other, { onClick: function (event) {
            if (mode === 'light') {
                setMode('dark');
            }
            else {
                setMode('light');
            }
            onClick === null || onClick === void 0 ? void 0 : onClick(event);
        }, sx: __spreadArray([
            {
                '& > *:first-child': {
                    display: mode === 'dark' ? 'none' : 'initial',
                },
                '& > *:last-child': {
                    display: mode === 'light' ? 'none' : 'initial',
                },
            }
        ], (Array.isArray(sx) ? sx : [sx]), true) }, { children: [_jsx(DarkModeRoundedIcon, {}), _jsx(LightModeIcon, {})] })));
}
