var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ChatListItem from '../ChatListItem';
import InfiniteScroll from 'react-infinite-scroll-component'; // Import InfiniteScroll
export default function ChatsPane(props) {
    var chats = props.chats, setSelectedChat = props.setSelectedChat, selectedChatId = props.selectedChatId, loadMoreChats = props.loadMoreChats, hasMoreChats = props.hasMoreChats;
    return (_jsxs(Sheet, __assign({ id: "scrollableChatsPane" // Add an ID for scrollable target
        , sx: {
            borderRight: '1px solid',
            borderColor: 'divider',
            height: { sm: 'calc(100vh - var(--Header-height))', md: '100vh' },
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
        } }, { children: [_jsx(Box, __assign({ sx: { p: 2 } }, { children: _jsx(Typography, __assign({ component: "h1", sx: { fontSize: { xs: 'md', md: 'lg' }, fontWeight: 'lg' } }, { children: "Sessions" })) })), _jsx(InfiniteScroll, __assign({ dataLength: chats.length, next: loadMoreChats, hasMore: hasMoreChats, loader: _jsx("h4", { children: "Loading..." }), scrollableTarget: "scrollableChatsPane" // Specify the scrollable container
                , style: { overflow: 'unset' } }, { children: _jsx(List, __assign({ sx: {
                        py: 0,
                        '--ListItem-paddingY': '0.75rem',
                        '--ListItem-paddingX': '1rem',
                    } }, { children: chats.map(function (chat) { return (_jsx(ChatListItem, __assign({}, chat, { unread: chat.unread, endDateTime: chat.endDateTime, duration: chat.duration, lastMessageText: chat.sender.lastMessageText, setSelectedChat: setSelectedChat, selectedChatId: selectedChatId }), chat.id)); }) })) }))] })));
}
