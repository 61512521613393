/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from 'styled-components';
import { fonts } from '../styles/typography';
import { parseFonts } from '../utils/style';
var GlobalStyles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  * {\n    box-sizing: border-box;\n    font-family: ", ", sans-serif;\n  }\n\n  body {\n    margin: 0;\n  }\n"], ["\n  ", "\n\n  * {\n    box-sizing: border-box;\n    font-family: ", ", sans-serif;\n  }\n\n  body {\n    margin: 0;\n  }\n"])), parseFonts(fonts), fonts.regular.name);
export default GlobalStyles;
var templateObject_1;
