var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Stack, Card, Box, Divider, Switch, Link, FormLabel, FormControl, Input, Alert, Chip } from '@mui/joy';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import InfoIcon from '@mui/icons-material/Info';
export default function SIPRefer(props) {
    var _a, _b, _c, _d, _e, _f;
    var flow = props.flow, invalidFields = props.invalidFields, onSIPEscalationIsEnabledChange = props.onSIPEscalationIsEnabledChange, onSIPReferURIChange = props.onSIPReferURIChange;
    return (_jsxs(Card, { children: [_jsx(Box, __assign({ sx: { mb: 1 } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }, { children: [_jsxs(Stack, __assign({ spacing: 1, sx: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(DialerSipIcon, { sx: { mr: 1 } }), _jsx(Typography, __assign({ level: "title-md" }, { children: "SIP REFER" })), _jsx(Chip, __assign({ sx: { ml: 1 }, color: "primary" }, { children: "Voice" }))] })), _jsxs(Stack, __assign({ spacing: 2, sx: { pr: 2 } }, { children: [_jsxs(Typography, __assign({ level: "body-sm" }, { children: ["You can enable SIP REFER escalation to forward the call back to your call center solution when the AI Agent cannot handle the request or if the caller needs to talk to a human agent.", _jsx(Link, __assign({ sx: { ml: 1 } }, { children: "Learn More" }))] })), !((_a = flow.sipDeploymentConfig) === null || _a === void 0 ? void 0 : _a.isEnabled) && _jsx(Alert, __assign({ variant: "soft", color: "warning", startDecorator: _jsx(InfoIcon, {}) }, { children: _jsx(Typography, __assign({ level: "body-sm" }, { children: "To use SIP REFER, you need to first enable SIP in the Deployment tab." })) }))] }))] })), ((_b = flow.sipDeploymentConfig) === null || _b === void 0 ? void 0 : _b.isEnabled) && _jsx(Switch, { checked: (_c = flow.sipEscalationConfig) === null || _c === void 0 ? void 0 : _c.isEnabled, onChange: onSIPEscalationIsEnabledChange, size: "lg", variant: "outlined" })] })) })), ((_d = flow.sipDeploymentConfig) === null || _d === void 0 ? void 0 : _d.isEnabled) && ((_e = flow.sipEscalationConfig) === null || _e === void 0 ? void 0 : _e.isEnabled) &&
                _jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Stack, __assign({ spacing: 2, sx: { my: 1 } }, { children: _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(FormLabel, { children: "SIP REFER URI" }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Provide the SIP REFER URI for the call to be forwarded in case of escalation." })), _jsx(FormControl, __assign({ sx: { display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "SIP REFER URI", error: invalidFields.some(function (e) { return e.field === 'sipEscalationConfig.sipBackUrl'; }), value: (_f = flow.sipEscalationConfig) === null || _f === void 0 ? void 0 : _f.sipBackUrl, onChange: onSIPReferURIChange }) }))] })) }))] })] }));
}
