var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { setResponsiveStyles } from '../../../utils/style';
import { size, other, border, flexBox, position, background, marginPadding } from './common';
var setStyles = function (props) { return "\n  ".concat(size(props), "\n  ").concat(other(props), "\n  ").concat(border(props), "\n  ").concat(flexBox(props), "\n  ").concat(position(props), "\n  ").concat(background(props), "\n  ").concat(marginPadding(props), "\n"); };
var ViewStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), setStyles, function (props) { return setResponsiveStyles(props, setStyles); });
export default ViewStyled;
var templateObject_1;
