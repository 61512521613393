var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, Stack, Textarea, Typography } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import "./styles.css";
import { useCallHistory } from "../../data/hooks/useCallHistory";
var DeepCallLogs = function () {
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var sessionId = queryParams.get("sessionId");
    var _a = useCallHistory(), getDeepLogsBySessionId = _a.getDeepLogsBySessionId, callDeepLogs = _a.callDeepLogs, callDeepLogsIsLoading = _a.callDeepLogsIsLoading;
    var mode = useColorScheme().mode;
    var _b = useState(mode === "dark"), darkMode = _b[0], setDarkMode = _b[1];
    useEffect(function () {
        if (sessionId) {
            getDeepLogsBySessionId(sessionId);
        }
        else {
            console.error("No sessionId provided in the URL.");
        }
    }, [sessionId]);
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    var defaultColDef = useMemo(function () { return ({
        filter: true,
        resizable: true,
    }); }, []);
    var rowData = useMemo(function () {
        return (callDeepLogs === null || callDeepLogs === void 0 ? void 0 : callDeepLogs.map(function (log) { return ({
            timestamp: log.timestamp,
            message: log.message,
        }); })) || [];
    }, [callDeepLogs]);
    var columnDefs = [
        {
            headerName: "Timestamp",
            field: "timestamp",
            flex: 1,
            cellRenderer: function (params) { return (_jsx(Typography, __assign({ level: "body-sm", sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                } }, { children: new Date(params.value).toLocaleString() }))); },
        },
        {
            headerName: "Message",
            field: "message",
            wrapText: true,
            autoHeight: true,
            flex: 6,
            cellRenderer: function (params) { return (_jsx(Textarea, { sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "10px",
                    boxSizing: "border-box",
                    height: "100%",
                }, size: "sm", value: params.value })); },
        },
    ];
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: _jsxs(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: ["Deep Logs for Session ", sessionId] })) })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: _jsx(Box, __assign({ sx: { width: "100%", height: "100%", paddingBottom: 1 }, className: !darkMode ? "ag-theme-quartz" : "ag-theme-quartz-dark" }, { children: callDeepLogsIsLoading ? (_jsx(Box, { children: "Loading deep logs..." })) : callDeepLogs && callDeepLogs.length > 0 ? (_jsx(AgGridReact, { rowData: rowData, columnDefs: columnDefs, cellSelection: true, defaultColDef: defaultColDef })) : (_jsx(Box, { children: "No data available" })) })) }))] })));
};
export default DeepCallLogs;
