var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useRecoilState } from 'recoil';
import { useRequest } from '../../hooks/useRequest';
import { uploadsAtom, uploadsLoadingAtom, uploadFileLoadingAtom } from '../store';
export var useUploads = function () {
    var request = useRequest();
    var _a = useRecoilState(uploadsAtom), uploads = _a[0], setUploads = _a[1];
    var _b = useRecoilState(uploadsLoadingAtom), uploadsLoading = _b[0], setUploadsLoading = _b[1];
    var _c = useRecoilState(uploadFileLoadingAtom), uploadFileLoading = _c[0], setUploadFileLoading = _c[1];
    var getUploads = function () {
        setUploadsLoading(true);
        request.get('/File')
            .then(function (_a) {
            var data = _a.data;
            setUploads(data);
        })
            .catch(console.log)
            .finally(function () { return setUploadsLoading(false); });
    };
    var startUploadProcessing = function () {
        void request.post('/File/startprocessing');
    };
    var uploadFile = function (file) {
        setUploadFileLoading(true);
        request.post('/File', file, { headers: { "Content-Type": "multipart/form-data" } })
            .then(function (_a) {
            var data = _a.data;
            setUploads(__spreadArray(__spreadArray([], uploads, true), [__assign(__assign({}, data), { isProcessing: true })], false));
            startUploadProcessing();
        })
            .catch(function (error) {
            console.log(error);
            if (error.response.data.message) {
                alert(error.response.data.message);
            }
        })
            .finally(function () { return setUploadFileLoading(false); });
    };
    var deleteUpload = function (fileId) {
        request.delete("/File/".concat(fileId))
            .then(function () { return setUploads(uploads.filter(function (item) { return item.fileId !== fileId; })); })
            .catch(console.log);
    };
    return { getUploads: getUploads, uploads: uploads, uploadsLoading: uploadsLoading, uploadFile: uploadFile, uploadFileLoading: uploadFileLoading, deleteUpload: deleteUpload };
};
