var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import MessagesPaneHeader from "../MessagesPaneHeader";
import AvatarWithStatus from "../AvatarWithStatus";
import ChatBubble from "../ChatBubble";
export default function MessagesPane(props) {
    var chat = props.chat;
    return (_jsxs(Sheet, __assign({ sx: {
            height: { xs: "calc(100dvh - var(--Header-height))", md: "100dvh" },
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.level1",
        } }, { children: [_jsx(MessagesPaneHeader, { sender: chat.sender }), _jsx(Box, __assign({ sx: {
                    display: "flex",
                    flex: 1,
                    minHeight: 0,
                    px: 2,
                    py: 3,
                    overflowY: "scroll",
                    flexDirection: "column",
                } }, { children: _jsx(Stack, __assign({ spacing: 2, sx: { justifyContent: "flex-end" } }, { children: chat.messages.map(function (message, index) {
                        var isUser = message.sender.name === "User";
                        return (_jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { flexDirection: isUser ? "row-reverse" : "row" } }, { children: [_jsx(AvatarWithStatus, { online: message.sender.online, src: message.sender.avatar, size: isUser ? "md" : "sm" }), _jsx(ChatBubble, __assign({ variant: isUser ? "sent" : "received" }, message))] }), index));
                    }) })) }))] })));
}
