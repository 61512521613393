import { colors } from '../../../../styles/theme';
import { addMeasure, parseTypography } from '../../../../utils/style';
var other = function (_a) {
    var _b, _c;
    var typo = _a.typo, size = _a.size, color = _a.color, measure = _a.measure, center = _a.center, bold = _a.bold, opacity = _a.opacity, uppercase = _a.uppercase, lineHeight = _a.lineHeight, transition = _a.transition, whiteSpace = _a.whiteSpace, wordBreak = _a.wordBreak, fontStyle = _a.fontStyle, decoration = _a.decoration, hoverColor = _a.hoverColor;
    return "\n  ".concat(typo ? parseTypography(typo) : '', "\n\n  ").concat(size ? "font-size: ".concat(addMeasure(size, measure), ";") : '', "\n  ").concat(color ? "color: ".concat((_b = colors[color]) !== null && _b !== void 0 ? _b : color, ";") : '', "\n  ").concat(bold ? "font-weight: bold;" : '', "\n  ").concat(opacity ? "opacity: ".concat(opacity, ";") : '', "\n  ").concat(uppercase ? 'text-transform: uppercase;' : '', "\n  ").concat(transition ? "transition: ".concat(transition, ";") : '', "\n  ").concat(whiteSpace ? "white-space: ".concat(whiteSpace, ";") : '', "\n  ").concat(wordBreak ? "word-break: ".concat(wordBreak, ";") : '', "\n  ").concat(fontStyle ? "font-style: ".concat(fontStyle, ";") : '', "\n  ").concat(decoration ? "text-decoration: ".concat(decoration, ";") : '', "\n\n  display: block; \n\n  ").concat(lineHeight
        ? "\n        line-height: ".concat(addMeasure(lineHeight, measure), ";\n        ")
        : '', "\n  ").concat(center
        ? "\n        text-align: center;\n      "
        : '', "\n\n  &:hover {\n    color: ").concat(hoverColor ? (_c = colors[hoverColor]) !== null && _c !== void 0 ? _c : hoverColor : '', ";\n  }\n");
};
export default other;
