var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/joy';
import HubSpotTicketCreation from './components/HubSpotTicketCreation';
import SIPRefer from './components/SIPRefer';
import CallForwarding from './components/CallForwarding';
export default function Escalation(props) {
    var flow = props.flow, invalidFields = props.invalidFields, onHubSpotEscalationIsEnabledChange = props.onHubSpotEscalationIsEnabledChange, onHubSpotTicketPipelineChange = props.onHubSpotTicketPipelineChange, onHubSpotTicketPipelineStageChange = props.onHubSpotTicketPipelineStageChange, onHubSpotTicketPriorityChange = props.onHubSpotTicketPriorityChange, onSIPEscalationIsEnabledChange = props.onSIPEscalationIsEnabledChange, onSIPReferURIChange = props.onSIPReferURIChange, onCallForwardingEscalationIsEnabledChange = props.onCallForwardingEscalationIsEnabledChange, onCallForwardingEscalationNumberChange = props.onCallForwardingEscalationNumberChange;
    return (_jsxs(Stack, __assign({ spacing: 2, sx: { flexGrow: 1 } }, { children: [_jsx(SIPRefer, { flow: flow, invalidFields: invalidFields, onSIPEscalationIsEnabledChange: onSIPEscalationIsEnabledChange, onSIPReferURIChange: onSIPReferURIChange }), _jsx(CallForwarding, { flow: flow, invalidFields: invalidFields, onCallForwardingEscalationIsEnabledChange: onCallForwardingEscalationIsEnabledChange, onCallForwardingEscalationNumberChange: onCallForwardingEscalationNumberChange }), _jsx(HubSpotTicketCreation, { flow: flow, invalidFields: invalidFields, onHubSpotEscalationIsEnabledChange: onHubSpotEscalationIsEnabledChange, onHubSpotTicketPipelineChange: onHubSpotTicketPipelineChange, onHubSpotTicketPipelineStageChange: onHubSpotTicketPipelineStageChange, onHubSpotTicketPriorityChange: onHubSpotTicketPriorityChange })] })));
}
