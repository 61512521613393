var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '../../../../styles/theme';
// import { AlignType } from '../index';
export var TableStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var TableHeaderStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 42px;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  height: 42px;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n"])), colors.grey10, colors.borderLight);
export var TableBodyStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var HeaderColumnStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1 1;\n  display: flex;\n  padding: 0 16px;\n  align-items: center;\n  border-right: 1px solid ", ";\n  ", ";\n  justify-content: ", ";\n"], ["\n  flex: 1 1;\n  display: flex;\n  padding: 0 16px;\n  align-items: center;\n  border-right: 1px solid ", ";\n  ", ";\n  justify-content: ", ";\n"])), colors.borderLight, function (_a) {
    var width = _a.width;
    return width ? "\n    width: ".concat(width, "px;\n    max-width: ").concat(width, "px;\n  ") : '';
}, function (_a) {
    var align = _a.align;
    return align === 'right' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start';
});
export var RowStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 40px;\n  display: flex;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  height: 40px;\n  display: flex;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), colors.white, colors.borderLight, colors.grey10);
export var ColumnStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 1;\n  height: 100%;\n  padding: 0 16px;\n  align-items: center;\n  overflow: hidden;\n  border-right: 1px solid ", ";\n  ", ";\n  justify-content: ", ";\n"], ["\n  display: flex;\n  flex: 1 1;\n  height: 100%;\n  padding: 0 16px;\n  align-items: center;\n  overflow: hidden;\n  border-right: 1px solid ", ";\n  ", ";\n  justify-content: ", ";\n"])), colors.borderLight, function (_a) {
    var width = _a.width;
    return width ? "\n    width: ".concat(width, "px;\n    max-width: ").concat(width, "px;\n  ") : '';
}, function (_a) {
    var align = _a.align;
    return align === 'right' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
