var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Stack, Card, Box, Divider, Switch, Link, FormLabel, FormControl, Input, Chip } from "@mui/joy";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
export default function CallForwarding(props) {
    var _a, _b, _c;
    var flow = props.flow, invalidFields = props.invalidFields, onCallForwardingEscalationIsEnabledChange = props.onCallForwardingEscalationIsEnabledChange, onCallForwardingEscalationNumberChange = props.onCallForwardingEscalationNumberChange;
    return (_jsxs(Card, { children: [_jsx(Box, __assign({ sx: { mb: 1 } }, { children: _jsxs(Box, __assign({ sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    } }, { children: [_jsxs(Stack, __assign({ spacing: 1, sx: {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            } }, { children: [_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(LocalPhoneIcon, { sx: { mr: 1 } }), _jsx(Typography, __assign({ level: "title-md" }, { children: "Call Forwarding" })), _jsx(Chip, __assign({ sx: { ml: 1 }, color: "primary" }, { children: "Voice" }))] })), _jsx(Stack, __assign({ spacing: 2, sx: { pr: 2 } }, { children: _jsxs(Typography, __assign({ level: "body-sm" }, { children: ["You can enable Call Forwarding escalation to forward the call back to a specific phone number when the AI Agent cannot handle the request or if the caller needs to talk to a human agent.", " ", _jsx(Link, __assign({ sx: { ml: 1 } }, { children: "Learn More" }))] })) }))] })), _jsx(Switch, { checked: (_a = flow.callForwardingEscalationConfig) === null || _a === void 0 ? void 0 : _a.isEnabled, onChange: onCallForwardingEscalationIsEnabledChange, size: "lg", variant: "outlined" })] })) })), ((_b = flow.callForwardingEscalationConfig) === null || _b === void 0 ? void 0 : _b.isEnabled) && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Stack, __assign({ spacing: 2, sx: { my: 1 } }, { children: _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(FormLabel, { children: "Call Forwarding Number" }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Provide the Phone Number for the call to be forwarded in case of escalation." })), _jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Call Forwarding Number +1 XXX XXX XXXX", error: invalidFields.some(function (e) {
                                            return e.field ===
                                                "callForwardingEscalationConfig.forwardToNumber";
                                        }), value: (_c = flow.callForwardingEscalationConfig) === null || _c === void 0 ? void 0 : _c.forwardToNumber, onChange: onCallForwardingEscalationNumberChange }) }))] })) }))] }))] }));
}
