var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { Box, Typography, Chip } from "@mui/joy";
import { TreeItem2Content, TreeItem2IconContainer, TreeItem2Root, TreeItem2GroupTransition, } from "@mui/x-tree-view/TreeItem2";
import { unstable_useTreeItem2 as useTreeItem, } from "@mui/x-tree-view/useTreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
// Define custom styled components
var CustomTreeItemRoot = styled(TreeItem2Root)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.secondary,
    });
});
var CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        marginRight: theme.spacing(1),
    });
});
var CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginLeft: 0
        },
        _b["& .content"] = {
            paddingLeft: theme.spacing(2),
        },
        _b);
});
var CustomTreeItemContent = styled(TreeItem2Content)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        "&.expanded": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        "&.focused, &.selected, &.selected.focused": {
            backgroundColor: "var(--tree-view-bg-color, ".concat(theme.palette.action.selected, ")"),
            color: "var(--tree-view-color)",
        },
    });
});
// Define the CustomTreeItem component
export var CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    var id = props.id, itemId = props.itemId, label = props.label, disabled = props.disabled, children = props.children, LabelIcon = props.labelIcon, labelInfo = props.labelInfo;
    var _a = useTreeItem({
        id: id,
        itemId: itemId,
        children: children,
        label: label,
        disabled: disabled,
        rootRef: ref,
    }), getContentProps = _a.getContentProps, getGroupTransitionProps = _a.getGroupTransitionProps, status = _a.status;
    return (_jsxs(CustomTreeItemRoot, { children: [_jsxs(CustomTreeItemContent, __assign({}, getContentProps({
                className: clsx("content", {
                    expanded: status.expanded,
                    selected: status.selected,
                    focused: status.focused,
                }),
            }), { children: [_jsx(CustomTreeItemIconContainer, { children: _jsx(TreeItem2Icon, { status: status }) }), _jsxs(Box, __assign({ sx: {
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                            p: 0.5,
                            pr: 0,
                        } }, { children: [_jsx(Box, { component: LabelIcon, color: "inherit", sx: { mr: 1 } }), _jsx(Typography, __assign({ color: "neutral" }, { children: label })), labelInfo ? (_jsx(Chip, __assign({ sx: { ml: 1 }, color: labelInfo === "Processing"
                                    ? "success"
                                    : labelInfo === "Invalid"
                                        ? "danger"
                                        : "neutral" }, { children: _jsx(Typography, __assign({ level: "body-md", color: "neutral" }, { children: labelInfo })) }))) : null] }))] })), children && (_jsx(CustomTreeItemGroupTransition, __assign({}, getGroupTransitionProps())))] }));
});
