import { useRecoilState } from 'recoil';
import { generateQueryParams } from '../../utils';
import { workspaceConversationsDashboardStatsAtom, workspaceConversationCommonTopicsStatsAtom, getWorkspaceConversationCommonTopicsStatsIsLoadingAtom, workspaceConversationKnowledgeBaseGapsStatsAtom, workspaceConversationEscalatedStatsAtom, workspaceConversationSentimentStatsAtom, workspaceConversationSessionsStatsAtom, workspaceConversationsSentimentByCommonTopicsStatsAtom, getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoadingAtom, workspaceConversationSessionsBySentimentFilteredAtom } from '../store';
import { useRequest } from '../../hooks/useRequest';
export var useDashboard = function () {
    var _a = useRecoilState(workspaceConversationSessionsBySentimentFilteredAtom), workspaceConversationSessionsBySentimentFiltered = _a[0], setWorkspaceConversationSessionsBySentimentFiltered = _a[1];
    var _b = useRecoilState(workspaceConversationSessionsStatsAtom), workspaceConversationSessionsStats = _b[0], setWorkspaceConversationSessionsStats = _b[1];
    var _c = useRecoilState(workspaceConversationSentimentStatsAtom), workspaceConversationSentimentStats = _c[0], setWorkspaceConversationSentimentStats = _c[1];
    var _d = useRecoilState(workspaceConversationEscalatedStatsAtom), workspaceConversationEscalatedStats = _d[0], setWorkspaceConversationEscalatedStats = _d[1];
    var _e = useRecoilState(workspaceConversationKnowledgeBaseGapsStatsAtom), workspaceConversationKnowledgeBaseGapsStats = _e[0], setWorkspaceConversationKnowledgeBaseGapsStats = _e[1];
    var _f = useRecoilState(workspaceConversationsDashboardStatsAtom), workspaceConversationsDashboardStats = _f[0], setWorkspaceConversationsDashboardStats = _f[1];
    var _g = useRecoilState(workspaceConversationCommonTopicsStatsAtom), workspaceConversationsCommonTopicsStats = _g[0], setWorkspaceConversationCommonTopicsStats = _g[1];
    var _h = useRecoilState(workspaceConversationsSentimentByCommonTopicsStatsAtom), workspaceConversationsSentimentByCommonTopicsStats = _h[0], setWorkspaceConversationsSentimentByCommonTopicsStats = _h[1];
    var _j = useRecoilState(getWorkspaceConversationCommonTopicsStatsIsLoadingAtom), isWorkspaceConversationCommonTopicsStatsIsLoading = _j[0], setIsWorkspaceConversationCommonTopicsStatsIsLoading = _j[1];
    var _k = useRecoilState(getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoadingAtom), isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading = _k[0], setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading = _k[1];
    var request = useRequest();
    var getWorkspaceConversationsDashboardStats = function () {
        request.get('/ConversationAnalytics/workspaceConversationsDashboardStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationsDashboardStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationKnowledgeBaseGapsStats = function () {
        request.get('/ConversationAnalytics/workspaceConversationKnowledgeBaseGapsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationKnowledgeBaseGapsStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceEscalatedConversationStats = function () {
        request.get('/ConversationAnalytics/workspaceConversationEscalatedStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationEscalatedStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationSessionsStats = function () {
        request.get('/ConversationAnalytics/workspaceConversationSessionStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationSessionsStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationSentimentStats = function () {
        request.get('/ConversationAnalytics/workspaceConversationSentimentStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationSentimentStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationSessionsBySentimentFiltered = function (topic, sentiment) {
        request.get("/ConversationAnalytics/workspaceConversationSessionsBySentimentFiltered?".concat(generateQueryParams({ topic: topic, sentiment: sentiment })))
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationSessionsStats(data);
        }) //Same Ui as sentiment Stats
            .catch(console.log);
    };
    var getWorkspaceConversationsCommonTopicsStats = function () {
        setIsWorkspaceConversationCommonTopicsStatsIsLoading(true);
        request.get('/ConversationAnalytics/workspaceConversationCommonTopicsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationCommonTopicsStats(data);
        })
            .catch(console.log)
            .finally(function () { return setIsWorkspaceConversationCommonTopicsStatsIsLoading(false); });
    };
    var getWorkspaceConversationsSentimentByCommonTopicsStats = function () {
        setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading(true);
        request.get('/ConversationAnalytics/workspaceConversationSentimentsByCommonTopicsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationsSentimentByCommonTopicsStats(data);
        })
            .catch(console.log)
            .finally(function () { return setIsWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading(false); });
    };
    return {
        workspaceConversationsDashboardStats: workspaceConversationsDashboardStats,
        workspaceConversationsCommonTopicsStats: workspaceConversationsCommonTopicsStats,
        isWorkspaceConversationCommonTopicsStatsIsLoading: isWorkspaceConversationCommonTopicsStatsIsLoading,
        workspaceConversationKnowledgeBaseGapsStats: workspaceConversationKnowledgeBaseGapsStats,
        workspaceConversationEscalatedStats: workspaceConversationEscalatedStats,
        workspaceConversationSentimentStats: workspaceConversationSentimentStats,
        workspaceConversationSessionsStats: workspaceConversationSessionsStats,
        workspaceConversationsSentimentByCommonTopicsStats: workspaceConversationsSentimentByCommonTopicsStats,
        isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading: isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading,
        workspaceConversationSessionsBySentimentFiltered: workspaceConversationSessionsBySentimentFiltered,
        getWorkspaceConversationSessionsBySentimentFiltered: getWorkspaceConversationSessionsBySentimentFiltered,
        getWorkspaceConversationsSentimentByCommonTopicsStats: getWorkspaceConversationsSentimentByCommonTopicsStats,
        getWorkspaceConversationSessionsStats: getWorkspaceConversationSessionsStats,
        getWorkspaceConversationSentimentStats: getWorkspaceConversationSentimentStats,
        getWorkspaceEscalatedConversationStats: getWorkspaceEscalatedConversationStats,
        getWorkspaceConversationKnowledgeBaseGapsStats: getWorkspaceConversationKnowledgeBaseGapsStats,
        getWorkspaceConversationsDashboardStats: getWorkspaceConversationsDashboardStats,
        getWorkspaceConversationsCommonTopicsStats: getWorkspaceConversationsCommonTopicsStats
    };
};
