var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext, useEffect } from 'react';
var ImpersonationContext = createContext({
    isImpersonated: false,
    impersonatedUserId: null,
    impersonatedWorkspaceId: null,
    impersonatedWorkspaceName: null,
    startUserImpersonation: function () { },
    startWorkspaceImpersonation: function () { },
    stopImpersonation: function () { },
});
export var ImpersonationProvider = function (_a) {
    var children = _a.children;
    var _b = useState(localStorage.getItem('iid')), impersonatedUserId = _b[0], setImpersonatedUserId = _b[1];
    var _c = useState(localStorage.getItem('mwiid')), impersonatedWorkspaceId = _c[0], setImpersonatedWorkspaceId = _c[1];
    var _d = useState(localStorage.getItem('mwiid_name')), impersonatedWorkspaceName = _d[0], setImpersonatedWorkspaceName = _d[1];
    var isImpersonated = Boolean(impersonatedUserId || impersonatedWorkspaceId);
    var startUserImpersonation = function (userId) {
        setImpersonatedUserId(userId);
        localStorage.setItem('iid', userId);
        window.location.reload();
    };
    var startWorkspaceImpersonation = function (workspaceId, workspaceName) {
        setImpersonatedWorkspaceId(workspaceId);
        setImpersonatedWorkspaceName(workspaceName);
        localStorage.setItem('mwiid', workspaceId);
        localStorage.setItem('mwiid_name', workspaceName);
        window.location.reload();
    };
    var stopImpersonation = function () {
        setImpersonatedUserId(null);
        setImpersonatedWorkspaceId(null);
        setImpersonatedWorkspaceName(null);
        localStorage.removeItem('iid');
        localStorage.removeItem('mwiid');
        localStorage.removeItem('mwiid_name');
        window.location.reload();
    };
    useEffect(function () {
        // Sync state with localStorage changes (e.g., from other tabs)
        var handleStorageChange = function () {
            setImpersonatedUserId(localStorage.getItem('iid'));
            setImpersonatedWorkspaceId(localStorage.getItem('mwiid'));
            setImpersonatedWorkspaceName(localStorage.getItem('mwiid_name'));
        };
        window.addEventListener('storage', handleStorageChange);
        return function () { return window.removeEventListener('storage', handleStorageChange); };
    }, []);
    return (_jsx(ImpersonationContext.Provider, __assign({ value: {
            isImpersonated: isImpersonated,
            impersonatedUserId: impersonatedUserId,
            impersonatedWorkspaceId: impersonatedWorkspaceId,
            impersonatedWorkspaceName: impersonatedWorkspaceName,
            startUserImpersonation: startUserImpersonation,
            startWorkspaceImpersonation: startWorkspaceImpersonation,
            stopImpersonation: stopImpersonation,
        } }, { children: children })));
};
export var useImpersonation = function () { return useContext(ImpersonationContext); };
