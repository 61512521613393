var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { LOADING_STATE } from "../constants/useAutoGeneratedContentloadingStates";
export var useAutoGeneratedContent = function () {
    var request = useRequest();
    var _a = useState([]), generatedArticles = _a[0], setGeneratedArticles = _a[1];
    var _b = useState(false), generatedArticlesLoading = _b[0], setGeneratedArticlesLoading = _b[1];
    var _c = useState([]), generatedInstructions = _c[0], setGeneratedInstructions = _c[1];
    var _d = useState(false), generatedInstructionsLoading = _d[0], setGeneratedInstructionsLoading = _d[1];
    var _e = useState({}), loadingStates = _e[0], setLoadingStates = _e[1];
    var _f = useState(null), article = _f[0], setArticle = _f[1];
    var _g = useState(null), instruction = _g[0], setInstruction = _g[1];
    var setLoadingState = function (key, state) {
        setLoadingStates(function (prevStates) {
            var _a;
            return (__assign(__assign({}, prevStates), (_a = {}, _a[key] = state, _a)));
        });
    };
    var getGeneratedArticles = function () {
        setGeneratedArticlesLoading(true);
        request
            .get("/AutoGeneratedContent/articles")
            .then(function (_a) {
            var data = _a.data;
            setGeneratedArticles(data);
        })
            .catch(console.log)
            .finally(function () { return setGeneratedArticlesLoading(false); });
    };
    var getGeneratedInstructions = function () {
        setGeneratedInstructionsLoading(true);
        request
            .get("/AutoGeneratedContent/instructions")
            .then(function (_a) {
            var data = _a.data;
            setGeneratedInstructions(data);
        })
            .catch(console.log)
            .finally(function () { return setGeneratedInstructionsLoading(false); });
    };
    var changeArticleDraftState = function (fileId, isDraft) {
        setLoadingState(LOADING_STATE.CHANGE_ARTICLE_DRAFT_STATE(fileId), true);
        request
            .put("/AutoGeneratedContent/articles/state/".concat(fileId, "?isDraft=").concat(isDraft))
            .then(function () {
            var updatedArticles = generatedArticles.map(function (article) {
                return article.fileId === fileId ? __assign(__assign({}, article), { isDraft: isDraft }) : article;
            });
            setGeneratedArticles(updatedArticles);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.CHANGE_ARTICLE_DRAFT_STATE(fileId), false); });
    };
    var changeInstructionDraftState = function (fileId, isDraft) {
        setLoadingState(LOADING_STATE.CHANGE_INSTRUCTION_DRAFT_STATE(fileId), true);
        request
            .put("/AutoGeneratedContent/instructions/state/".concat(fileId, "?isDraft=").concat(isDraft))
            .then(function () {
            var updatedInstructions = generatedInstructions.map(function (instruction) {
                return instruction.fileId === fileId ? __assign(__assign({}, instruction), { isDraft: isDraft }) : instruction;
            });
            setGeneratedInstructions(updatedInstructions);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.CHANGE_INSTRUCTION_DRAFT_STATE(fileId), false); });
    };
    var deleteGeneratedArticle = function (fileId) {
        setLoadingState(LOADING_STATE.DELETE_GENERATED_ARTICLE(fileId), true);
        request
            .delete("/AutoGeneratedContent/articles/".concat(fileId))
            .then(function () {
            return setGeneratedArticles(generatedArticles.filter(function (item) { return item.fileId !== fileId; }));
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.DELETE_GENERATED_ARTICLE(fileId), false); });
    };
    var deleteGeneratedInstruction = function (fileId) {
        setLoadingState(LOADING_STATE.DELETE_GENERATED_INSTRUCTION(fileId), true);
        request
            .delete("/AutoGeneratedContent/instructions/".concat(fileId))
            .then(function () {
            return setGeneratedInstructions(generatedInstructions.filter(function (item) { return item.fileId !== fileId; }));
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.DELETE_GENERATED_INSTRUCTION(fileId), false); });
    };
    var getArticleContent = function (fileId) {
        setLoadingState(LOADING_STATE.GET_ARTICLE_CONTENT(fileId), true);
        request
            .get("/AutoGeneratedContent/articles/".concat(fileId))
            .then(function (_a) {
            var data = _a.data;
            setArticle(data);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.GET_ARTICLE_CONTENT(fileId), false); });
    };
    var getInstructionContent = function (fileId) {
        setLoadingState(LOADING_STATE.GET_ARTICLE_CONTENT(fileId), true);
        request
            .get("/AutoGeneratedContent/instructions/".concat(fileId))
            .then(function (_a) {
            var data = _a.data;
            setInstruction(data);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.GET_ARTICLE_CONTENT(fileId), false); });
    };
    var updateArticleContent = function (fileId, fileContent) {
        setLoadingState(LOADING_STATE.UPDATE_ARTICLE_CONTENT(fileId), true);
        request
            .put("/AutoGeneratedContent/articles/content/".concat(fileId), { fileContent: fileContent })
            .then(function () {
            var updatedArticles = generatedArticles.map(function (article) {
                return article.fileId === fileId ? __assign(__assign({}, article), { fileContent: fileContent }) : article;
            });
            setGeneratedArticles(updatedArticles);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.UPDATE_ARTICLE_CONTENT(fileId), false); });
    };
    var updateInstructionContent = function (fileId, fileContent) {
        setLoadingState(LOADING_STATE.UPDATE_INSTRUCTION_CONTENT(fileId), true);
        request
            .put("/AutoGeneratedContent/instructions/content/".concat(fileId), { fileContent: fileContent })
            .then(function () {
            var updatedInstructions = generatedInstructions.map(function (instruction) {
                return instruction.fileId === fileId ? __assign(__assign({}, instruction), { fileContent: fileContent }) : instruction;
            });
            setGeneratedInstructions(updatedInstructions);
        })
            .catch(console.log)
            .finally(function () { return setLoadingState(LOADING_STATE.UPDATE_INSTRUCTION_CONTENT(fileId), false); });
    };
    return {
        getGeneratedInstructions: getGeneratedInstructions,
        getGeneratedArticles: getGeneratedArticles,
        deleteGeneratedInstruction: deleteGeneratedInstruction,
        deleteGeneratedArticle: deleteGeneratedArticle,
        changeArticleDraftState: changeArticleDraftState,
        changeInstructionDraftState: changeInstructionDraftState,
        getArticleContent: getArticleContent,
        updateArticleContent: updateArticleContent,
        updateInstructionContent: updateInstructionContent,
        getInstructionContent: getInstructionContent,
        article: article,
        instruction: instruction,
        generatedArticles: generatedArticles,
        generatedInstructions: generatedInstructions,
        generatedArticlesLoading: generatedArticlesLoading,
        generatedInstructionsLoading: generatedInstructionsLoading,
        loadingStates: loadingStates,
    };
};
