var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Dashboard } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import Uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Modal, ModalDialog, ModalClose, DialogTitle, DialogContent } from "@mui/joy";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { API_URL } from "../../../constants";
import "@uppy/core/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
var UppyFileUploader = function (_a) {
    var acceptedFileTypes = _a.acceptedFileTypes, buttonText = _a.buttonText, fileActionUrl = _a.fileActionUrl, onUploadComplete = _a.onUploadComplete;
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var uppy = useState(function () { return createUppyInstance(); })[0];
    useEffect(function () {
        // This cleanup function will reset Uppy when the component is unmounted
        return function () { return uppy.clear(); };
    }, [uppy]);
    function createUppyInstance() {
        var uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: acceptedFileTypes,
            },
        })
            .use(DragDrop)
            .use(XHR, {
            endpoint: API_URL + fileActionUrl,
            onBeforeRequest: function (xhr) {
                return __awaiter(this, void 0, void 0, function () {
                    var token;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getAccessTokenSilently()];
                            case 1:
                                token = _a.sent();
                                xhr.setRequestHeader("Authorization", "Bearer ".concat(token));
                                return [2 /*return*/];
                        }
                    });
                });
            },
            onAfterResponse: function (xhr) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (xhr.status === 200) {
                            onUploadComplete();
                        }
                        return [2 /*return*/];
                    });
                });
            },
        });
        return uppyInstance;
    }
    return (_jsxs(Box, { children: [_jsx(Button, __assign({ startDecorator: _jsx(CloudUploadIcon, {}), onClick: function () { return setIsModalOpen(true); }, color: "primary", variant: "solid" }, { children: buttonText })), _jsx(Modal, __assign({ open: isModalOpen, onClose: function () { return setIsModalOpen(false); }, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined", size: "lg", layout: "center" }, { children: [_jsx(ModalClose, {}), _jsx(DialogTitle, { children: "Upload Recordings" }), _jsx(DialogContent, __assign({ sx: { p: 2 } }, { children: _jsx(Dashboard, { uppy: uppy }) }))] })) }))] }));
};
export default UppyFileUploader;
