// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container .w-md-editor-text-pre > code {
    font-size: 16px !important;
    line-height: 20px !important;
}

.editor-container .w-md-editor-text-input {
    font-size: 16px !important;
    line-height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/MdEditorStyles/editorStyles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC","sourcesContent":[".editor-container .w-md-editor-text-pre > code {\n    font-size: 16px !important;\n    line-height: 20px !important;\n}\n\n.editor-container .w-md-editor-text-input {\n    font-size: 16px !important;\n    line-height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
