var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRecoilState } from 'recoil';
import { addWebsiteIsLoadingAtom, getWebsiteIsLoadingAtom, widgetScriptAtom, crawlingDataAtom, limitationAtom, workspaceConversationsDashboardStatsAtom, workspaceConversationCommonTopicsStatsAtom, getWorkspaceConversationCommonTopicsStatsIsLoadingAtom } from '../store';
import { useRequest } from '../../hooks/useRequest';
export var useWebsite = function () {
    var _a = useRecoilState(addWebsiteIsLoadingAtom), addWebsiteIsLoading = _a[0], setAddWebsiteIsLoading = _a[1];
    var _b = useRecoilState(getWebsiteIsLoadingAtom), getWebsiteIsLoading = _b[0], setGetWebsiteIsLoading = _b[1];
    var _c = useRecoilState(widgetScriptAtom), widgetScript = _c[0], setWidgetScript = _c[1];
    var _d = useRecoilState(crawlingDataAtom), crawlingData = _d[0], setCrawlingData = _d[1];
    var _e = useRecoilState(limitationAtom), limitation = _e[0], setLimitation = _e[1];
    var _f = useRecoilState(workspaceConversationsDashboardStatsAtom), workspaceConversationsDashboardStats = _f[0], setWorkspaceConversationsDashboardStats = _f[1];
    var _g = useRecoilState(workspaceConversationCommonTopicsStatsAtom), workspaceConversationsCommonTopicsStats = _g[0], setWorkspaceConversationCommonTopicsStats = _g[1];
    var _h = useRecoilState(getWorkspaceConversationCommonTopicsStatsIsLoadingAtom), getWorkspaceConversationCommonTopicsStatsIsLoading = _h[0], setGetWorkspaceConversationCommonTopicsStatsIsLoading = _h[1];
    var request = useRequest();
    var getCrawlingStatus = function (onFinally) {
        request.get('/Crawling')
            .then(function (_a) {
            var data = _a.data;
            var mainDomain = data.mainDomain, isCrawlingFinished = data.isCrawlingFinished, crawledPagesCount = data.crawledPagesCount;
            if (!mainDomain) {
                return;
            }
            if (isCrawlingFinished && crawledPagesCount === 0) {
                setCrawlingData({ isFailed: true, mainDomain: mainDomain, crawledPagesCount: crawledPagesCount });
            }
            else if (isCrawlingFinished) {
                setCrawlingData({ isCrawled: true, mainDomain: mainDomain, crawledPagesCount: crawledPagesCount });
            }
            else if (crawledPagesCount >= 0) {
                setCrawlingData({ inProgress: true, mainDomain: mainDomain, crawledPagesCount: crawledPagesCount });
                setTimeout(getCrawlingStatus, 2000);
            }
        })
            .catch(console.log)
            .finally(onFinally);
    };
    var addWebsite = function (url) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setAddWebsiteIsLoading(true);
            request.post('/Crawling', { url: url })
                .then(function (_a) {
                var crawlingStarted = _a.data;
                if (crawlingStarted) {
                    setGetWebsiteIsLoading(true);
                    setTimeout(function () {
                        getCrawlingStatus(function () {
                            setGetWebsiteIsLoading(false);
                        });
                    }, 1500);
                }
            })
                .catch(function (_a) {
                var response = _a.response;
                if (response.data) {
                    alert(response.data);
                }
            })
                .finally(function () { return setAddWebsiteIsLoading(false); });
            return [2 /*return*/];
        });
    }); };
    var getWebsite = function () {
        setGetWebsiteIsLoading(true);
        getCrawlingStatus(function () {
            setGetWebsiteIsLoading(false);
        });
    };
    var getWidgetScript = function () {
        request.get('/Crawling/widgetScript')
            .then(function (_a) {
            var data = _a.data;
            return setWidgetScript(data);
        })
            .catch(console.log);
    };
    var getLimitation = function () {
        request.get('/limitation')
            .then(function (_a) {
            var data = _a.data;
            return setLimitation(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationsDashboardStats = function () {
        request.get('api/ConversationAnalytics/workspaceConversationsDashboardStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationsDashboardStats(data);
        })
            .catch(console.log);
    };
    var getWorkspaceConversationsCommonTopicsStats = function () {
        setGetWorkspaceConversationCommonTopicsStatsIsLoading(true);
        request.get('api/ConversationAnalytics/workspaceConversationCommonTopicsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationCommonTopicsStats(data);
        })
            .catch(console.log)
            .finally(function () { return setGetWorkspaceConversationCommonTopicsStatsIsLoading(false); });
    };
    var recrawlWebsite = function () {
        setAddWebsiteIsLoading(true);
        request.post('/Crawling/recrawl')
            .then(function (_a) {
            var crawlingStarted = _a.data;
            if (crawlingStarted) {
                setCrawlingData({
                    inProgress: true,
                    mainDomain: (crawlingData === null || crawlingData === void 0 ? void 0 : crawlingData.mainDomain) || '',
                    crawledPagesCount: (crawlingData === null || crawlingData === void 0 ? void 0 : crawlingData.crawledPagesCount) || 0
                });
                setTimeout(function () {
                    getCrawlingStatus();
                }, 5000);
            }
        })
            .catch(console.log)
            .finally(function () { return setAddWebsiteIsLoading(false); });
    };
    return {
        addWebsite: addWebsite,
        getWebsite: getWebsite,
        getWidgetScript: getWidgetScript,
        getLimitation: getLimitation,
        recrawlWebsite: recrawlWebsite,
        workspaceConversationsDashboardStats: workspaceConversationsDashboardStats,
        workspaceConversationsCommonTopicsStats: workspaceConversationsCommonTopicsStats,
        limitation: limitation,
        crawlingData: crawlingData,
        widgetScript: widgetScript,
        addWebsiteIsLoading: addWebsiteIsLoading,
        getWebsiteIsLoading: getWebsiteIsLoading,
        getWorkspaceConversationCommonTopicsStatsIsLoading: getWorkspaceConversationCommonTopicsStatsIsLoading,
        getWorkspaceConversationsDashboardStats: getWorkspaceConversationsDashboardStats,
        getWorkspaceConversationsCommonTopicsStats: getWorkspaceConversationsCommonTopicsStats
    };
};
