export var API_URL = process.env.REACT_APP_API_BASE_ADRESS;
export var JUNE_KEY = process.env.REACT_APP_JUNE_KEY;
export var INJECT_DOWORK_IDENTIFIER_CODE = process.env.REACT_APP_INJECT_DOWORK_IDENTIFIER_CODE;
export var INJECT_DOWORK_IDENTIFIER_CODE_WORKSPACEID = process.env.REACT_APP_INJECT_DOWORK_IDENTIFIER_CODE_WORKSPACEID;
export var APP_ENV = process.env.REACT_APP_ENV;
export var DOWORK_POPUP_OPENED = 'DOWORK_POPUP_OPENED';
export var DOWORK_WIDGET_TESTING = 'DOWORK_WIDGET_TESTING';
export var STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export var STRIPE_BASIC_PRICE_ID = process.env.REACT_APP_STRIPE_BASIC_PRICE_ID;
export var STRIPE_PRO_PRICE_ID = process.env.REACT_APP_STRIPE_PRO_PRICE_ID;
var INTERCOM_Z_INDEX = 2147483003;
export var HIGH_Z_INDEXES = {
    LEVEL_1: INTERCOM_Z_INDEX + 1,
    LEVEL_2: INTERCOM_Z_INDEX + 2,
    LEVEL_3: INTERCOM_Z_INDEX + 3 // Alert, Dropdown sub
};
