/** @format */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var statusColors = {
    red: '#FF0000',
    blue: '#0078FF',
    green: '#7ED321',
    grey: '#E3E6ED',
    orange: '#FFC100',
    purple: '#BE41EF',
    cyan: '#84DCDD',
    pink: '#FFADF0'
};
var schemeColors = {
    theme: '0,120,255',
    warning: '252,184,85',
    success: '60,155,0',
    danger: '224,0,0',
    dark: '32,31,30',
    slate: '227,230,237',
    cadet: '227,230,238',
    light: '242,243,246',
    lime: '94,215,0',
    rose: '255,56,56' // #FF3838
};
var fullSchemeColorsVariants = Object.entries(schemeColors).reduce(function (value, item) {
    var colorVariants = {};
    colorVariants[item[0]] = "rgb(".concat(item[1], ")");
    for (var i = 5; i <= 95; i += 5)
        colorVariants[item[0] + i] = "rgba(".concat(item[1], ",0.").concat("".concat(i).padStart(2, '0'), ")");
    return __assign(__assign({}, value), colorVariants);
}, {});
export var colors = __assign(__assign({ none: 'transparent', 
    // theme colors
    theme: '#0078FF', themeDark: '#106EBE', themeStrong: '#005A9E', themeLight: '#C7E0F4', themeSlight: '#E5EEFC', themeEasiest: '#F2F8FF', aqua: '#80C5E5', aquaStrong: '#008ACA', mintLight: '#D8E6EA', mint: '#A7C5CA', mintStrong: '#75A2AF', danger: '#E00000', dangerDark: '#C60000', dangerStrong: '#A80000', dangerLight: '#FFCCD2', dangerSlight: '#FEF2F2', success: '#3C9B00', successLight: '#C0E5D1', successSlight: '#F2FAF6', warning: '#FCB855', warningLight: '#FFEAB3', warningSlight: '#FFF8EE', 
    // overlay colors
    overlayLight: '#ffffff73', overlayStrong: '#00000073', 
    // border colors
    borderLight: '#EDEBE9', borderGray: '#8A8886', borderDark: '#323130', background: '#FBFBFB', 
    // grey colors
    white: '#fff', grey10: '#FAF9F8', grey20: '#F3F2F1', grey30: '#EDEBE9', grey40: '#E1DFDD', grey50: '#D2D0CE', grey60: '#C8C6C4', grey90: '#A19F9D', grey130: '#605E5C', grey150: '#3B3A39', grey160: '#323130', grey190: '#201F1E', black: '#000', defaultTextColor: '#201F1E' }, fullSchemeColorsVariants), statusColors);
